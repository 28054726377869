import React from 'react';
import {
  Autocomplete,
  LinearProgress,
  ListSubheader,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Unstable_Grid2';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { styled } from '@mui/styles';
import { CustomScrollbars } from '../Table/CustomScrollbarsVirtualList';
import T from 'i18n';

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
  };

  if (dataSet[3].hasOwnProperty('group') && dataSet[3].group) {
    return (
      <ListSubheader
        key={dataSet.key}
        component="div"
        style={{ ...inlineStyle, marginLeft: `${dataSet[3].level || 0}rem` }}
      >
        {dataSet[1]}
      </ListSubheader>
    );
  }

  return (
    <Typography
      key={index}
      component="li"
      {...dataSet[0]}
      noWrap
      style={{ ...inlineStyle, marginLeft: `${dataSet[3].level || 0}rem` }}
    >
      {dataSet[1]}
    </Typography>
  );
}

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return (
    <div ref={ref} {...props} {...outerProps}>
      <CustomScrollbars
        {...(props as any)}
        style={{ height: 'calc(100% - 1px)' }}
        forwardedRef={ref}
      />
    </div>
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];

    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactElement) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * 8}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export default ({
  idx,
  originalArray,
  filterName,
  filterKey,
  valKey,
  labelKey,
  setFilters,
  filterCategory,
  loading,
}: {
  idx: number;
  originalArray: any;
  filterName?: string;
  filterKey: string;
  valKey?: string;
  labelKey?: string;
  filterCategory: any;
  setFilters: (newFilterCategory: any) => void;
  loading: boolean | null;
}) => {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <React.Fragment key={idx}>
      <Grid xs={3}>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {T.translate(filterName || filterKey)}
        </Typography>
      </Grid>
      <Grid xs={9}>
        {loading ? (
          <LinearProgress
            sx={{
              marginTop: '1rem',
            }}
          />
        ) : (
          <Autocomplete
            multiple
            inputValue={inputValue}
            onClose={() => {
              setInputValue('');
            }}
            onInputChange={(event, value, reason) => {
              if (reason !== 'reset') {
                setInputValue(value);
              }
            }}
            size="small"
            options={originalArray || []}
            getOptionLabel={(option: any) => (labelKey ? option[labelKey] : option)}
            disableCloseOnSelect
            renderInput={params => <TextField {...params} variant="standard" />}
            value={
              valKey
                ? (originalArray || []).filter(
                    (val: any) =>
                      filterCategory &&
                      filterCategory[filterKey] &&
                      filterCategory[filterKey].includes(val[valKey]),
                  )
                : (filterCategory && filterCategory[filterKey]) || []
            }
            onChange={(e, value: any) => {
              setFilters({
                ...filterCategory,
                [filterKey]: valKey ? value.map((val: any) => val[valKey]) : value,
              });
            }}
            ListboxComponent={ListboxComponent}
            disableListWrap
            PopperComponent={StyledPopper}
            renderOption={(props, option, state) =>
              [props, labelKey ? option[labelKey] : option, state.index, option] as React.ReactNode
            }
            renderGroup={params => params as any}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};
