import React from 'react';
import T from 'i18n';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material';
import { useBookmarks } from 'features/common/redux/bookmarks';

const BookmarksList = ({ filters, handleChangeFilters }: any) => {
  const { fetchBookmarks, fetchBookmarksPending } = useBookmarks();
  const [showBookmarks, setShowBookmarks] = React.useState(false);
  const [bookmarksList, setBookmarksList] = React.useState<any>(null);

  const getBookMarks = () => {
    setShowBookmarks(!showBookmarks);
    if (!bookmarksList)
      fetchBookmarks({ type: 'documents' }).then((res: any) => setBookmarksList(res));
  };

  return (
    <>
      <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center', py: 0 }}>
        <Typography sx={{ color: 'lightgray' }}>{T.translate('case.bookmarks')}</Typography>
        <IconButton onClick={() => getBookMarks()}>
          {showBookmarks ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse in={showBookmarks}>
        {fetchBookmarksPending ? (
          <ListItem sx={{ justifyContent: 'center' }}>
            <CircularProgress size={30} />
          </ListItem>
        ) : (
          bookmarksList &&
          bookmarksList.map((bookmark: any) => (
            <ListItem
              key={bookmark.id}
              sx={{ justifyContent: 'space-between', alignItems: 'center', py: 0 }}
            >
              <Typography sx={{ pl: '0.5rem' }}>{bookmark.name}</Typography>
              <Checkbox
                color="primary"
                name={bookmark.id}
                onChange={e => {
                  handleChangeFilters({
                    bookmarks: e.target.checked
                      ? [...filters.bookmarks, { id: bookmark.id, name: bookmark.name }]
                      : filters.bookmarks.filter((b: any) => b.id !== bookmark.id),
                  });
                }}
                checked={!!filters.bookmarks.find((b: any) => b.id === bookmark.id)}
                sx={{ '&.Mui-checked': { color: '#7BA0F4' } }}
              />
            </ListItem>
          ))
        )}
      </Collapse>
    </>
  );
};

export default BookmarksList;
