import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import history from 'common/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectCurrentPageNumber,
  selectDocSearchLocation,
  selectDocSearchVal,
  selectDocuments,
  selectIsGlobalPageLookup,
  selectMatchedUrl,
  selectPresentQuery,
  selectSpecificParam,
  selectUseBackEndForPresentMode,
} from 'common/selectors';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { openNewBackgroundTab } from 'utils/browser';
import { fileTypes } from '../enums';
import equal from 'react-fast-compare';
import DocumentSearch from '../DocumentSearch';
import FileViewer from '../FileViewer';
import useFolderSpecificFileDetails from './hooks/useFolderSpecificFileDetails';
import ConfidentialityRing from './ConfidentialityRing';
import { usePrevious } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { setCurrentSelectedFile } from '../redux/setCurrentSelectedFile';

type ViewerWrapperProps = {
  fileId: string;
  fileType: string;
};

export default ({ fileId, fileType }: ViewerWrapperProps) => {
  const dispatch = useDispatch();
  const caseId = useSelector(selectCurrentCase).id;
  const currentFolder = useSelector(selectCurrentFolder);
  const currentUrl = useSelector(selectMatchedUrl);
  const isTrialBundle = currentFolder?.type === 'trialbook';
  const isGlobalPageLookUp = useSelector(selectIsGlobalPageLookup) as boolean;
  const docSearchVal = useSelector(selectDocSearchVal);
  const docSearchLocation = useSelector(selectDocSearchLocation);
  const currentPageNumber = useSelector(selectCurrentPageNumber) as any;
  const present = useSelector(selectPresentQuery);
  const documents = useSelector(selectDocuments) as Array<any>;
  const useBackEndForPresentMode = useSelector(selectUseBackEndForPresentMode);
  //@ts-ignore
  const file = useSelector(state => selectSpecificParam(state, 'file'));
  const prevFile = usePrevious(file);
  const globalPaging = currentFolder?.globalPaging;
  const isPresentModePage =
    currentUrl.includes(`${caseId}/present-mode`) && !useBackEndForPresentMode;
  const isTagsFolder = currentFolder?.type === 'tags';

  const fileViewerRef = useRef<any>();

  const [searchDetails, setSearchDetails] = useState(null);
  const [searchFunctions, setSearchFunctions] = useState(null);

  const isSearchModeRefreshed =
    file && documents.length === 0 && currentUrl.includes(`${caseId}/search-mode`);

  const { currentFileMetaData, bundleSpecificFileDetails } = useFolderSpecificFileDetails(
    isTrialBundle,
    globalPaging,
    isGlobalPageLookUp,
    isPresentModePage,
    isTagsFolder,
    isSearchModeRefreshed,
  );

  const searchHandler = useCallback((sd: any) => {
    setSearchDetails((prevSearchDetails: any) => {
      if (!equal(prevSearchDetails, sd)) return sd;
      return prevSearchDetails;
    });
  }, []); // useCallback to prevent rerendering for file viewer

  const searchFunctionsHandler = useCallback((sf: any) => {
    setSearchFunctions(sf);
  }, []); // useCallback to prevent rerendering for file viewer

  useEffect(() => {
    if (prevFile && prevFile !== file) {
      setSearchDetails(null);
      setSearchFunctions(null);
    }
  }, [prevFile, file]);

  const isGlobalPaging =
    globalPaging ||
    isGlobalPageLookUp ||
    (bundleSpecificFileDetails && Object.keys(bundleSpecificFileDetails).length > 0) ||
    (isPresentModePage &&
      currentFileMetaData &&
      Object.keys(currentFileMetaData).includes('folderId')) ||
    ((isTagsFolder || isSearchModeRefreshed) &&
      currentFileMetaData &&
      Object.keys(currentFileMetaData).includes('folderId'));
  const fileStartPage =
    currentFileMetaData?.startPage && currentFileMetaData?.startPage.includes('.')
      ? currentFileMetaData?.startPage.split('.')
      : currentFileMetaData?.startPage;

  const isStartPageFirstDigitAfterDecimalZero =
    fileStartPage && fileStartPage.length > 1 && fileStartPage[1].startsWith('0');

  return (
    <div className="case-viewer-wrapper">
      <Container fluid className="h-100">
        <Row className="justify-content-between align-items-center p-2 px-3">
          <Breadcrumb>
            <Breadcrumb.Item active={true}>{fileId}</Breadcrumb.Item>
            {isGlobalPaging &&
              currentFileMetaData &&
              Object.keys(currentFileMetaData).includes('startPage') && (
                <Breadcrumb.Item active={true}>
                  {`${currentFileMetaData.globalPagePrefix}${currentFileMetaData.startPage}-${
                    currentFileMetaData.startPage.includes('.')
                      ? `${fileStartPage[0]}.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}` +
                        ((isStartPageFirstDigitAfterDecimalZero
                          ? parseInt(fileStartPage[1].slice(1, fileStartPage[1].length), 10)
                          : parseInt(fileStartPage[1], 10)) +
                          parseInt(currentFileMetaData.pageCount, 10) -
                          1)
                      : parseInt(currentFileMetaData.startPage, 10) +
                        parseInt(currentFileMetaData.pageCount, 10) -
                        1
                  } ${currentFileMetaData.tab ? ` [Tab ${currentFileMetaData.tab}]` : ''}`}
                </Breadcrumb.Item>
              )}
            <ConfidentialityRing confidentialityRing={currentFileMetaData?.confidentialityRing} />
          </Breadcrumb>
          {(docSearchVal || docSearchLocation) &&
            searchFunctions &&
            fileViewerRef.current &&
            currentFileMetaData &&
            (fileId === currentFileMetaData.id ||
              (currentFileMetaData.file && fileId === currentFileMetaData.file.id)) && (
              <DocumentSearch
                searchFunctions={searchFunctions}
                docSearchVal={docSearchVal}
                docSearchLocation={docSearchLocation}
              />
            )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="actions-buttons">
              <FontAwesomeIcon
                onClick={() => {
                  const goToPage = currentPageNumber || 1;
                  if (fileViewerRef.current && fileViewerRef.current.pausePlayer)
                    fileViewerRef.current.pausePlayer();
                  if (present) {
                    fileViewerRef.current &&
                      fileViewerRef.current.stopPresent &&
                      fileViewerRef.current.stopPresent();
                  }
                  const urlWithParams =
                    fileType === fileTypes.mp4
                      ? present
                        ? `&present=${present}`
                        : ''
                      : present && isGlobalPaging && currentFileMetaData
                      ? `&tab=${currentFileMetaData.tab}&startPage=${currentFileMetaData.startPage}&pageCount=${currentFileMetaData.pageCount}&globalPagePrefix=${currentFileMetaData.globalPagePrefix}&present=${present}`
                      : present && !isGlobalPaging
                      ? `&present=${present}`
                      : !present && isGlobalPaging && currentFileMetaData
                      ? `&tab=${currentFileMetaData.tab}&startPage=${currentFileMetaData.startPage}&pageCount=${currentFileMetaData.pageCount}&globalPagePrefix=${currentFileMetaData.globalPagePrefix}`
                      : '';
                  openNewBackgroundTab(
                    `/case/${caseId}/files/${fileId}?folderId=${currentFolder.id || 'notAFolder'}${
                      goToPage > 1 ? `&goToPage=${goToPage}` : ''
                    }${urlWithParams}`,
                  );
                }}
                icon={faExternalLinkAlt}
              />
              <FontAwesomeIcon
                onClick={() => {
                  history.push(history.location.pathname.replace(/\/files.*/, ``));
                  dispatch(setCurrentSelectedFile(null));
                }}
                icon={faTimesCircle}
              />
            </div>
          </div>
        </Row>
        <Row className="secondRow">
          <Col md={12} className="h-100">
            <Card>
              <FileViewer
                ref={fileViewerRef}
                bundleSpecificFileDetails={bundleSpecificFileDetails}
                searchHandler={searchHandler}
                searchFunctionsHandler={searchFunctionsHandler}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
