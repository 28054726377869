import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  CASE_FETCH_MATCHES_BEGIN,
  CASE_FETCH_MATCHES_SUCCESS,
  CASE_FETCH_MATCHES_FAILURE,
  CASE_FETCH_MATCHES_DISMISS_FEEDBACK,
  CASE_CLEAR_MATCHES,
} from './constants';
import api from 'common/api';
import { selectCaseDetails, selectUserSettings, withCurrentCaseId } from 'common/selectors';
import { getNonce } from 'utils/nonce';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const fetchMatches = createPromiseAction(CASE_FETCH_MATCHES_BEGIN);

export function dismissFetchMatchesFeedback() {
  return {
    type: CASE_FETCH_MATCHES_DISMISS_FEEDBACK,
  };
}

export function clearMatches() {
  return {
    type: CASE_CLEAR_MATCHES,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doFetchMatches(action) {
  const {
    payload: { caseId, searchTerm, searchLocation, bookmarks },
  } = action;

  if (!searchTerm) {
    return yield put({
      type: CASE_FETCH_MATCHES_SUCCESS,
      data: {
        results: [],
        isDeepSearchPerformed: false,
      },
    });
  }
  const nonce = getNonce(6);
  const userSettings = yield select(selectUserSettings);
  const caseDetails = yield select(selectCaseDetails);

  const res = yield call(api.post, `/cases/${caseId}/matches`, {
    nonce,
    search: searchTerm,
    filters: { folders: searchLocation, bookmarks },
    numMatches:
      (userSettings.aiSettings && userSettings.aiSettings.numMatches) ||
      (caseDetails.aiSettings &&
        caseDetails.aiSettings.matches &&
        caseDetails.aiSettings.matches.numMatches) ||
      10,
    multiQuery:
      (userSettings.aiSettings && userSettings.aiSettings.multiQuery) ||
      (caseDetails.aiSettings &&
        caseDetails.aiSettings.matches &&
        caseDetails.aiSettings.matches.multiQuery) ||
      false,
    maxMarginalRelevance:
      (userSettings.aiSettings && userSettings.aiSettings.maxMarginalRelevance) ||
      (caseDetails.aiSettings &&
        caseDetails.aiSettings.matches &&
        caseDetails.aiSettings.matches.maxMarginalRelevance) ||
      false,
    index: (userSettings.aiSettings && userSettings.aiSettings.index) || '',
  });

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_MATCHES_FAILURE,
      feedback: {
        message: 'feedback.fetchMatchesFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  if (res.nonce === nonce) {
    yield put({
      type: CASE_FETCH_MATCHES_SUCCESS,
      data: {
        results: res.results,
        isDeepSearchPerformed: true,
      },
    });
    yield call(resolvePromiseAction, action, res);
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchMatches() {
  yield takeLatest(fetchMatches, withCurrentCaseId(doFetchMatches));
}

export function useFetchMatches() {
  const dispatch = useDispatch();

  const { matches, fetchDocumentsPending, fetchDocumentsFeedback } = useSelector(
    state => ({
      matches: state.case.matches,
      fetchDocumentsPending: state.case.fetchDocumentsPending,
      fetchDocumentsFeedback: state.case.fetchDocumentsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchMatches(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchMatchesFeedback());
  }, [dispatch]);

  const boundClearMatches = useCallback(() => {
    return dispatch(clearMatches());
  }, [dispatch]);

  return {
    matches,
    fetchMatches: boundAction,
    fetchDocumentsPending,
    fetchDocumentsFeedback,
    dismissFetchMatchesFeedback: boundDismissFeedback,
    clearMatches: boundClearMatches,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_MATCHES_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchDocumentsPending: true,
        fetchDocumentsFeedback: action.feedback,
        documents: [],
        matches: [],
        answers: null,
        isDeepSearchPerformed: false,
      };

    case CASE_CLEAR_MATCHES:
      return {
        ...state,
        documents: [],
        matches: [],
        answers: null,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_MATCHES_SUCCESS:
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        matches: action.data.results,
        isDeepSearchPerformed: action.data.isDeepSearchPerformed,
      };

    case CASE_FETCH_MATCHES_FAILURE:
      return {
        ...state,
        fetchDocumentsPending: false,
        documents: [],
        matches: [],
        fetchDocumentsFeedback: action.feedback,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_MATCHES_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
