import React, { useState, useCallback, useEffect } from 'react';
import { CustomViewerButton } from 'features/common';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import clsx from 'clsx';
import CommentInput from './CommentInput';
import RedactionInput from './RedactionInput';
import LinkInput from './LinkInput';
import { convert, fixPosition } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faLink,
  faHighlighter,
  faComment,
  faRetweet,
  faSquare,
  faImage,
  faStamp,
} from '@fortawesome/free-solid-svg-icons';
import CrossRefInput from './CrossRefInput';
import TagSelect from '../../TagSelect';
import { Mark } from '@prizmdoc/viewer-core';
import throttle from 'lodash.throttle';
import { useSelector } from 'react-redux';
import { selectEnableRedaction, selectTimeline } from 'common/selectors';
import AddEvent from './AddEvent';

const useStyles = makeStyles(() => ({
  popupContainer: {
    backgroundColor: 'white',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    borderRadius: 0,
    alignItems: 'center',
    width: '25rem',
    zIndex: 999999,
  },
  markButtonContainer: {
    position: 'fixed',
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    borderRadius: '0.5rem',
    zIndex: 100,

    '&>:first-child': {
      flexGrow: 1,
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },

    '&>:last-child': {
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },

    '&.visible': {
      opacity: 1,
      transition: 'opacity 150ms',
      visibility: 'visible',
    },
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',

    border: 'none',
    boxShadow: '0 4px 6px 0 #666',

    color: 'white',
    textAlign: 'center',
    opacity: '0.85',

    '> div': {
      fontSize: '1.2rem',
    },

    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    "&[type='button']:hover, &div [type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
  noBorder: {
    border: 'none',
  },
  uploadIcon: {
    padding: '0.2rem',
    borderRadius: '50% !important',
    fontSize: '2rem',
    color: 'white',
    margin: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#4567e5',
  },
  disabled: {
    backgroundColor: '#A5A4A1',
  },
  error: {
    backgroundColor: 'red',
  },
}));

const Input = ({
  viewerControl,
  scrollContainer,
  mark,
  annotation,
  showCommentInput,
  showRedactionInput,
  updateHighlight,
  updateRedaction,
  deleteAnnotation,
  updateAnnotation,
  showLinkInput,
  updateLink,
  showCrossRefInput,
  updateCrossRefference,
}: any) => {
  return (
    <>
      {!!showCommentInput && (
        <CommentInput
          comment={mark.getData('comment')}
          selectedTags={annotation?.tags || []}
          onClick={showCommentInput.addHighlight}
          update={showCommentInput.addHighlight === updateHighlight}
          deleteAnnotation={() => deleteAnnotation(mark)}
          createdBy={annotation?.createdBy}
          selectMark={() => {
            // defer handle grabs for now
            // mark.interactionMode = Mark.InteractionMode.Full;
            viewerControl.selectMarks([mark]);
          }}
          page={scrollContainer}
          shared={annotation?.shared}
          onSharedClick={() => {
            updateAnnotation(
              annotation.annotation,
              annotation.id,
              annotation.tags,
              !annotation.shared,
            );
          }}
        />
      )}
      {!!showLinkInput && (
        <LinkInput
          onClick={showLinkInput.addLink}
          update={showLinkInput.addLink === updateLink}
          deleteAnnotation={() => deleteAnnotation(mark)}
          createdBy={annotation?.createdBy}
          page={scrollContainer}
          link={mark?.getData('link') && JSON.parse(mark?.getData('link'))}
          linkText={mark?.getData('linkText')}
        />
      )}
      {!!showCrossRefInput && (
        <CrossRefInput
          onClick={showCrossRefInput.addCrossReference}
          crossRef={mark?.getData('crossRef')}
          link={mark?.getData('link') && JSON.parse(mark?.getData('link'))}
          linkText={mark?.getData('linkText')}
          update={showCrossRefInput.addCrossReference === updateCrossRefference}
          deleteAnnotation={() => deleteAnnotation(mark)}
          createdBy={annotation?.createdBy}
          href={mark?.getData('href')}
          selectMark={() => {
            // defer handle grabs for now
            // mark.interactionMode = Mark.InteractionMode.Full;
            viewerControl.selectMarks([mark]);
          }}
          page={scrollContainer}
          burned={annotation?.burned}
          crossRefFileId={mark?.getData('linkId')}
        />
      )}

      {!!showRedactionInput && (
        <RedactionInput
          reason={mark.getData('reason')}
          onClick={showRedactionInput.addRedaction}
          update={showRedactionInput.addRedaction === updateRedaction}
          deleteAnnotation={() => deleteAnnotation(mark)}
          createdBy={annotation?.createdBy}
          selectMark={() => {
            // defer handle grabs for now
            // mark.interactionMode = Mark.InteractionMode.Full;
            viewerControl.selectMarks([mark]);
          }}
          page={scrollContainer}
        />
      )}
    </>
  );
};

const Buttons = ({
  mark,
  addHighlight,
  addRedaction,
  canCreateAndEditPublicHyperlinks,
  setShowCommentInput,
  setShowRedactionInput,
  setShowLinkInput,
  addLink,
  addRectangleHighlight,
  addRectangleRedaction,
  canCreateAndEditCrossRefs,
  setShowCrossRefInput,
  addCrossReference,
  copyText,
  setHideAll,
  annotation,
  markToBeCreated,
  addEventHandler,
}: any) => {
  const isText = mark.type === Mark.Type.HighlightAnnotation;
  const isRectangle = mark.type === Mark.Type.RectangleAnnotation;
  const isTimeline = useSelector(selectTimeline);
  const enableRedaction = useSelector(selectEnableRedaction);

  const classes = useStyles();

  return (
    <>
      {isText && (
        <CustomViewerButton onClick={() => addHighlight()} className={classes.markButton}>
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faHighlighter} />
          {T.translate('case.addHighlight')}
        </CustomViewerButton>
      )}
      {isText && (
        <CustomViewerButton
          onClick={() => setShowCommentInput({ addHighlight })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faComment} />
          {T.translate('case.addComment')}
        </CustomViewerButton>
      )}
      {isText && canCreateAndEditPublicHyperlinks && (
        <CustomViewerButton
          onClick={() => setShowLinkInput({ addLink })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faLink} />
          {T.translate('case.addLink')}
        </CustomViewerButton>
      )}
      {isText && enableRedaction && (
        <CustomViewerButton
          onClick={() => setShowRedactionInput({ addRedaction })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faStamp} />
          {T.translate('case.addRedaction')}
        </CustomViewerButton>
      )}
      {isRectangle && (
        <CustomViewerButton onClick={addRectangleHighlight} className={classes.markButton}>
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faImage} />
          {T.translate('case.addHighlight')}
        </CustomViewerButton>
      )}
      {isRectangle && (
        <CustomViewerButton
          onClick={() => setShowCommentInput({ addHighlight: addRectangleHighlight })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faSquare} />
          {T.translate('case.addComment')}
        </CustomViewerButton>
      )}
      {isRectangle && canCreateAndEditCrossRefs && (
        <CustomViewerButton
          onClick={() => setShowCrossRefInput({ addCrossReference })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faRetweet} />
          {T.translate('case.addCrossReference')}
        </CustomViewerButton>
      )}
      {isRectangle && enableRedaction && (
        <CustomViewerButton
          onClick={() => setShowRedactionInput({ addRedaction: addRectangleRedaction })}
          className={classes.markButton}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faStamp} />
          {T.translate('case.addRedaction')}
        </CustomViewerButton>
      )}
      <TagSelect
        startOpen={false}
        onOpen={() => {
          setHideAll(true);
        }}
        selectedTags={annotation?.tags || []}
        updateSelectedTag={
          markToBeCreated &&
          ((selectedTags: any) => {
            if (mark.type === Mark.Type.RectangleAnnotation) {
              addRectangleHighlight({ tags: selectedTags });
            } else {
              addHighlight({ tags: selectedTags });
            }
          })
        }
      />
      {isText && isTimeline && <AddEvent mark={mark} addEventHandler={addEventHandler} />}
      {isText && (
        <CustomViewerButton
          onClick={copyText}
          className={classes.markButton}
          style={{ borderLeft: '1px dashed white' }}
        >
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faCopy} />
          {T.translate('generic.copyText')}
        </CustomViewerButton>
      )}
    </>
  );
};

export default ({
  markToBeCreated,
  markToBeUpdated,
  updateAnnotation,
  highlights,
  viewerControl,
  scrollContainer,
  addHighlight,
  addRedaction,
  updateHighlight,
  updateRedaction,
  updateCrossRefference,
  updateLink,
  copyText,
  addLink,
  addRectangleHighlight,
  addRectangleRedaction,
  addCrossReference,
  deleteAnnotation,
  canCreateAndEditCrossRefs,
  canCreateAndEditPublicHyperlinks,
  addEventHandler,
}: any) => {
  const mark = markToBeCreated || markToBeUpdated;
  const enableRedaction = useSelector(selectEnableRedaction);

  const [, _updateState] = useState<any>();
  const updateState = throttle(() => _updateState({}), 50);
  const forceUpdate = useCallback(() => updateState(), [updateState]);
  const [showCommentInput, setShowCommentInput] = useState<{
    addHighlight: (props: any) => void;
  } | null>(null);
  const [showLinkInput, setShowLinkInput] = useState<{
    addLink: (props: any) => void;
  } | null>(null);
  const [showCrossRefInput, setShowCrossRefInput] = useState<{
    addCrossReference: (props: any) => void;
  } | null>(null);
  const [showRedactionInput, setShowRedactionInput] = useState<{
    addRedaction: (props: any) => void;
  } | null>(null);

  const [hideAll, setHideAll] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (!mark) return;
    // for to redraw on scroll
    scrollContainer.addEventListener('scroll', forceUpdate);
    // for to redraw on resize
    window.addEventListener('resize', forceUpdate);
    viewerControl.on('ScaleChanged', forceUpdate);
    viewerControl.on('DocumentRotated', forceUpdate);
    // TO-DO
    // // update to get ref
    // forceUpdate();
    return () => {
      window.removeEventListener('resize', forceUpdate);
      scrollContainer.removeEventListener('scroll', forceUpdate);
      viewerControl.off('ScaleChanged', forceUpdate);
      viewerControl.off('DocumentRotated', forceUpdate);
    };
  }, [mark, forceUpdate, scrollContainer, viewerControl]);

  useEffect(() => {
    if (!mark) return;

    setShowCommentInput(
      markToBeUpdated &&
        (markToBeUpdated.type === Mark.Type.RectangleAnnotation ||
          markToBeUpdated.type === Mark.Type.HighlightAnnotation) && {
          addHighlight: updateHighlight,
        },
    );
    setShowLinkInput(
      canCreateAndEditPublicHyperlinks &&
        markToBeUpdated &&
        markToBeUpdated.type === Mark.Type.TextHyperlinkAnnotation && {
          addLink: updateLink,
        },
    );

    setShowCrossRefInput(
      canCreateAndEditCrossRefs &&
        markToBeUpdated &&
        markToBeUpdated.type === Mark.Type.TextAnnotation && {
          addCrossReference: updateCrossRefference,
        },
    );

    setShowRedactionInput(
      enableRedaction &&
        markToBeUpdated &&
        (markToBeUpdated.type === Mark.Type.TextSelectionRedaction ||
          markToBeUpdated.type === Mark.Type.RectangleRedaction) && {
          addRedaction: updateRedaction,
        },
    );

    setHideAll(false);
  }, [
    mark,
    markToBeUpdated,
    updateHighlight,
    updateCrossRefference,
    updateLink,
    canCreateAndEditCrossRefs,
    canCreateAndEditPublicHyperlinks,
    updateRedaction,
    enableRedaction,
  ]);

  const getStyle = (el: HTMLElement | null, translateY: number) => {
    if (!el || !mark) return 'left: -100px; top: -100px;';

    try {
      const rectangle = el.getBoundingClientRect();
      const { clientWidth, clientX, clientY } = convert(mark, viewerControl);
      const x = clientX + clientWidth / 2 - rectangle.width / 2;
      const y = clientY - translateY;
      const { x: fixedX, y: fixedY } = fixPosition({ x, y }, rectangle);

      return `left: ${fixedX}px; top: ${fixedY}px;`;
    } catch (e) {
      return 'left: -100px; top: -100px;';
    }
  };

  const annotation = mark && highlights.find((item: any) => item.annotation.uid === mark.uid);

  if (!mark) return null;

  const isHighlightEvent = mark && mark.getData('subType') === 'highlightEvent';
  if (isHighlightEvent) return null;

  return showCommentInput || showLinkInput || showCrossRefInput || showRedactionInput ? (
    <div
      ref={(el: any) => {
        el?.setAttribute('style', getStyle(el, (mark?.boundingRectangle?.height || 20) / -2));
      }}
      key={mark.uid}
      className={clsx(classes.markButtonContainer, classes.popupContainer, {
        visible: !!mark && !hideAll,
      })}
    >
      <Input
        viewerControl={viewerControl}
        scrollContainer={scrollContainer}
        mark={mark}
        annotation={annotation}
        showCommentInput={showCommentInput}
        showRedactionInput={showRedactionInput}
        updateHighlight={updateHighlight}
        updateRedaction={updateRedaction}
        deleteAnnotation={deleteAnnotation}
        updateAnnotation={updateAnnotation}
        showLinkInput={showLinkInput}
        updateLink={updateLink}
        showCrossRefInput={showCrossRefInput}
        updateCrossRefference={updateCrossRefference}
      />
    </div>
  ) : (
    <div
      key={mark.uid}
      className={clsx(classes.markButtonContainer, { visible: !!mark && !hideAll })}
      ref={el => {
        el?.setAttribute('style', getStyle(el, 30));
      }}
    >
      <Buttons
        mark={mark}
        addHighlight={addHighlight}
        addRedaction={addRedaction}
        canCreateAndEditPublicHyperlinks={canCreateAndEditPublicHyperlinks}
        setShowCommentInput={setShowCommentInput}
        setShowRedactionInput={setShowRedactionInput}
        setShowLinkInput={setShowLinkInput}
        addLink={addLink}
        addRectangleHighlight={addRectangleHighlight}
        addRectangleRedaction={addRectangleRedaction}
        canCreateAndEditCrossRefs={canCreateAndEditCrossRefs}
        setShowCrossRefInput={setShowCrossRefInput}
        addCrossReference={addCrossReference}
        copyText={copyText}
        setHideAll={setHideAll}
        annotation={annotation}
        markToBeCreated={markToBeCreated}
        updateAnnotation={updateAnnotation}
        addEventHandler={addEventHandler}
      />
    </div>
  );
};
