import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_GET_MORE_INFO_FOLDER_BEGIN,
  CASE_GET_MORE_INFO_FOLDER_SUCCESS,
  CASE_GET_MORE_INFO_FOLDER_FAILURE,
  CASE_GET_MORE_INFO_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const getMoreInfoFolder = createPromiseAction(CASE_GET_MORE_INFO_FOLDER_BEGIN);

export function dismissGetMoreInfoFolderFeedback() {
  return {
    type: CASE_GET_MORE_INFO_FOLDER_DISMISS_FEEDBACK,
  };
}

export function* doGetMoreInfoFolder(action) {
  const { caseId, folderId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/folders/${folderId}/logs`);

  if (res && res.error) {
    yield put({
      type: CASE_GET_MORE_INFO_FOLDER_FAILURE,
      feedback: {
        message: 'feedback.getMoreInfoFolderFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_GET_MORE_INFO_FOLDER_SUCCESS,
    data: { moreInfoFolder: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchGetMoreInfoFolder() {
  yield takeLatest(getMoreInfoFolder, withCurrentCaseId(doGetMoreInfoFolder));
}

export function useGetMoreInfoFolder() {
  const dispatch = useDispatch();

  const { moreInfoFolder, getMoreInfoFolderPending, getMoreInfoFolderFeedback } = useSelector(
    state => ({
      moreInfoFolder: state.case.moreInfoFolder,
      getMoreInfoFolderPending: state.case.getMoreInfoFolderPending,
      getMoreInfoFolderFeedback: state.case.getMoreInfoFolderFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getMoreInfoFolder(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGetMoreInfoFolderFeedback());
  }, [dispatch]);

  return {
    moreInfoFolder,
    getMoreInfoFolder: boundAction,
    getMoreInfoFolderPending,
    getMoreInfoFolderFeedback,
    dismissGetMoreInfoFolderFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_GET_MORE_INFO_FOLDER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        getMoreInfoFolderPending: true,
        getMoreInfoFolderFeedback: null,
      };

    case CASE_GET_MORE_INFO_FOLDER_SUCCESS:
      // The request is success
      return {
        ...state,
        getMoreInfoFolderPending: false,
        getMoreInfoFolderFeedback: action.feedback,
        moreInfoFolder: action.data.moreInfoFolder,
      };

    case CASE_GET_MORE_INFO_FOLDER_FAILURE:
      // The request is failed
      return {
        ...state,
        getMoreInfoFolderPending: false,
        getMoreInfoFolderFeedback: action.feedback,
      };

    case CASE_GET_MORE_INFO_FOLDER_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        getMoreInfoFolderFeedback: null,
      };

    default:
      return state;
  }
}
