import { selectCurrentSelectedFileMetaData, selectSpecificParam } from 'common/selectors';
import { useFetchFolderSpecificFileDetails } from 'features/case/redux/fetchFolderSpecificFileDetails';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default (
  isTrialBundle: boolean,
  globalPaging: boolean,
  isGlobalPageLookUp: boolean,
  isPresentModePage: boolean,
  isTagsFolder: boolean,
  isSearchModeRefreshed: boolean,
) => {
  //@ts-ignore
  const file = useSelector(state => selectSpecificParam(state, 'file'));
  const fileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;

  const { fetchFolderSpecificFileDetails } = useFetchFolderSpecificFileDetails();

  const [currentFileMetaData, setCurrentFileMetaData] = useState();
  const [bundleSpecificFileDetails, setBundleSpecificFileDetails] = useState({} as any);

  //If SEARCH/team/private bundle show GP inside viewer from 1st trialbundle of bundle location
  useEffect(() => {
    const currentFileFirstTrialBundleLocation =
      (fileMetaData &&
        fileMetaData.bundleLocations &&
        fileMetaData.bundleLocations.length > 0 &&
        fileMetaData.bundleLocations.find((bundle: any) => bundle.containerType === 'trialbook')) ||
      {};

    if (
      ((isTrialBundle && !globalPaging) ||
        (!isTrialBundle && !isGlobalPageLookUp && !isPresentModePage)) &&
      ((currentFileFirstTrialBundleLocation &&
        Object.keys(currentFileFirstTrialBundleLocation).length > 0) ||
        isTagsFolder ||
        isSearchModeRefreshed) // this will display GP for opened file when user refresh in search mode
    ) {
      const fileId = isTagsFolder
        ? fileMetaData.fileId
        : isSearchModeRefreshed
        ? file
        : fileMetaData.id;

      if (!fileId) {
        setBundleSpecificFileDetails({});
        setCurrentFileMetaData(fileMetaData);
        return;
      }
      fetchFolderSpecificFileDetails({ fileId }).then((res: any) => {
        const bundleDetails = res.find(
          (item: any) => item.folderId === currentFileFirstTrialBundleLocation.folderId,
        );
        if (bundleDetails) {
          setCurrentFileMetaData({
            ...fileMetaData,
            ...bundleDetails,
          });
          setBundleSpecificFileDetails(bundleDetails);
        } else if (isTagsFolder || isSearchModeRefreshed) {
          const gpDetails = res.find((itm: any) => itm.containerType === 'trialbook') || {};
          if (gpDetails) {
            setCurrentFileMetaData({
              ...fileMetaData,
              ...gpDetails,
            });
            setBundleSpecificFileDetails(gpDetails);
          }
        } else {
          setBundleSpecificFileDetails({});
          setCurrentFileMetaData(fileMetaData);
        }
      });
    } else {
      setBundleSpecificFileDetails({});
      setCurrentFileMetaData(fileMetaData);
    }
  }, [
    fileMetaData,
    fetchFolderSpecificFileDetails,
    file,
    globalPaging,
    isGlobalPageLookUp,
    isPresentModePage,
    isSearchModeRefreshed,
    isTagsFolder,
    isTrialBundle,
  ]);

  return { currentFileMetaData, bundleSpecificFileDetails } as any;
};
