import React from 'react';
import { Menu, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import history from 'common/history';
import { useDispatch } from 'react-redux';
import { setCurrentSelectedFile } from '../redux/setCurrentSelectedFile';
import { setDocSearch } from 'features/viewing/redux/setDocSearch';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fetchFileDetails } from '../redux/fetchFileDetails';

interface SelectedMatchesContainerProps {
  matchesItm: any;
  handleMatchesMenuClose: any;
  anchorEl: any;
  openAnswerMenu: boolean;
  parentContainerId: any;
  isTranscriptPage?: boolean;
}

const SelectedMatchesContainer = ({
  matchesItm,
  handleMatchesMenuClose,
  anchorEl,
  openAnswerMenu,
  parentContainerId,
  isTranscriptPage,
}: SelectedMatchesContainerProps) => {
  const dispatch = useDispatch();
  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;
    const regex = new RegExp(highlight, 'gi');
    const matches = text.match(regex);
    if (matches) {
      return text.replace(regex, `<span style="background-color: #ffff00">${matches[0]}</span>`);
    }
    return text;
  };
  return (
    <Menu
      id="answer-macthes-menu"
      anchorEl={anchorEl}
      open={openAnswerMenu}
      onClose={handleMatchesMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuList>
        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '30vh' }}>
          {matchesItm.map((item: any, index: number) => (
            <Tooltip
              key={index}
              title={
                <div
                  dangerouslySetInnerHTML={{ __html: highlightText(item.text, item.highlight) }}
                ></div>
              }
              placement="right"
              arrow
            >
              <MenuItem
                key={index}
                onClick={() => {
                  handleMatchesMenuClose(parentContainerId);
                  history.push(
                    history.location.pathname.replace(/\/files.*/, ``) +
                      `/files/${item.fileId || item.file.id}`,
                  );
                  if (isTranscriptPage) {
                    dispatch(fetchFileDetails({ fileId: item.fileId })).then((res: any) => {
                      dispatch(setCurrentSelectedFile(res));
                    });
                  } else dispatch(setCurrentSelectedFile(item.file));
                  dispatch(
                    setDocSearch(item.highlight ? item.highlight : item.text, item.location),
                  );
                }}
              >
                <Typography sx={{ fontWeight: 600, color: '#0000007a', mr: '1rem' }}>
                  {item.srcIndex}
                </Typography>
                <Typography sx={{ fontWeight: 600, color: '#0000007a', mr: '1rem' }}>
                  {item.fileId || item.file.id}
                </Typography>
                <Typography sx={{ fontWeight: 600, color: '#0000007a' }}>
                  {item.fileName || item.filename || item.file.name}
                </Typography>
              </MenuItem>
            </Tooltip>
          ))}
        </PerfectScrollbar>
      </MenuList>
    </Menu>
  );
};

export default SelectedMatchesContainer;
