import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import history from 'common/history';
import NavBarLinks from './NavBarLinks';
import Search from './Search';
import Logo from './Logo';
import classnames from 'classnames';
import Notifications from './Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default class NavBar extends Component {
  static propTypes = {
    search: PropTypes.object,
    searchFunctions: PropTypes.object,
    showHearingRoom: PropTypes.bool,
    showLogo: PropTypes.bool,
  };

  static defaultProps = {
    showLogo: false,
  };

  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  }

  searchRef = null;

  render() {
    return (
      <Navbar className={classnames(this.props.theme, { 'nav-flex': this.props.isDataRoom })}>
        {this.props.showLogo && <Logo showText={false} />}
        {this.props.bundleLocations && (
          <Nav style={{ marginLeft: '0.5rem' }}>
            <div className="nav-bar-links">{this.props.bundleLocations}</div>
          </Nav>
        )}
        <Navbar.Brand
          style={{ ...((this.props.showLogo || this.props.bundleLocations) && { marginLeft: 0 }) }}
          className={this.props.className}
        >
          {this.props.brandLink ? (
            <>
              <span
                className="brandLink"
                onClick={() =>
                  history.push(
                    history.location.pathname.replace(/\/case.*/, ``) +
                      `/case/${this.props.brandLink.currentCaseId}`,
                  )
                }
              >
                {this.props.showBackIcon && <ChevronLeftIcon style={{ color: '#777' }} />}
                {this.props.brandText}
              </span>
            </>
          ) : (
            <span>
              {this.props.brandText}
              {this.props.confidentialityRingLabel && this.props.confidentialityRingLabel}
            </span>
          )}
        </Navbar.Brand>
        <Nav className="mr-auto" />
        <Nav>
          <Search
            ref={el => {
              this.searchRef = el && el.clear;
            }}
            search={this.props.search}
            searchFunctions={this.props.searchFunctions}
            hasSearch={this.props.hasSearch}
            clickToSearch={this.props.clickToSearch}
          />
          <Notifications mainTheme={this.props.theme} />
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          <NavBarLinks
            theme={this.props.theme}
            additionalButton={this.props.additionalButton}
            showHearingRoom={this.props.showHearingRoom}
          />
        </Nav>
      </Navbar>
    );
  }
}
