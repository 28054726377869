import React from 'react';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import { objectValueFromPath } from 'utils/object';
// import PropTypes from 'prop-types';

export default function ErrorFeedback({ name, errors, style = undefined }) {
  const value = objectValueFromPath(name, errors);
  return value ? (
    <Form.Control.Feedback type="invalid" style={style}>
      {value.type === 'required'
        ? value.message || T.translate('generic.fieldIsRequired')
        : value.type === 'invalid link'
        ? T.translate('generic.provideAValidLink')
        : value.type === 'isInvalidEmail'
        ? T.translate('generic.invalidEmail')
        : value.type === 'isExistingUser'
        ? T.translate('generic.userExists')
        : value.type === 'isValidDate'
        ? T.translate('generic.notAValidDate')
        : value.type === 'isValidTime'
        ? T.translate('generic.notAValidTime')
        : value.type === 'pattern' && name === 'name'
        ? T.translate('case.notValidBundleName')
        : T.translate('generic.notAValidEntry')}
    </Form.Control.Feedback>
  ) : null;
}

ErrorFeedback.propTypes = {};
ErrorFeedback.defaultProps = {};
