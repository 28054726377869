import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import MuiButton from '@mui/material/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import T from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFile } from '@fortawesome/free-solid-svg-icons';
import { Prompt } from 'react-router';
import { Modal } from 'features/common/MaterialBasedComponents';
import history from 'common/history';
import { confidentialityRingCategoryLabel } from 'features/case/enums';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  closeUploadFilesProgress,
  dismissUploadFilesFeedback,
  uploadFiles,
} from 'features/case/redux/uploadFiles';
import _ from 'lodash';
import { selectCurrentFolder } from 'common/selectors';

const processError = (error: any) => {
  switch (error.status) {
    default:
      if (typeof error.body === 'object')
        return error.body.body || error.body.statusText || error.body.message || '';
      else return error.body || error.statusText || '';
  }
};

const FileProgress = ({ id, name, progress, error }: any) => {
  return (
    <div className="file-container">
      <FontAwesomeIcon icon={faFile} />
      <div className="progress-container">
        <div className="title-container">
          <span>
            {[name, id || (error && error.body && error.body.fileId)].filter(Boolean).join(' - ')}
          </span>
          {error && processError(error)}
        </div>
        <ProgressBar
          variant={!error ? 'success' : error.status === 409 ? 'warning' : 'danger'}
          now={progress}
          label={progress ? `${progress.split('.')[0]}%` : ''}
        />
      </div>
    </div>
  );
};

const FileStatusRender = ({
  filesToUpload,
  loading,
}: {
  filesToUpload?: any[];
  loading?: boolean;
}) => {
  const currentFolder = useSelector(selectCurrentFolder) as any;

  const exportCSV = () => {
    const failedFiles = _.filter(filesToUpload, file => file?.error && file?.error?.body);
    const csvHeader = 'File Name, Error Message\n';
    const csvRows = failedFiles.map(file => `"${file.name}", "${file.error.body.body}"`).join('\n');
    const csvContent = csvHeader + csvRows;
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getMonth() +
      1}--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
    link.setAttribute('href', url);
    link.setAttribute('download', `${currentFolder?.name}_Failed_Uploads_${timeStamp}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [failed, setFailed] = useState(0);
  const [successful, setSuccessful] = useState(0);
  const [duplicates, setDuplicates] = useState(0);

  const getCounts = () => {
    const failedFiles = _.filter(filesToUpload, file => file?.error && file?.error?.body).length;
    const successfulFiles = _.filter(
      filesToUpload,
      file => !file?.error?.status && !file?.error?.body,
    ).length;
    const duplicateFiles = _.filter(filesToUpload, file => file?.error?.status === 409).length;
    setFailed(failedFiles);
    setSuccessful(successfulFiles);
    setDuplicates(duplicateFiles);
  };

  useEffect(() => {
    getCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);

  return (
    <div className="file-stats-row">
      {filesToUpload?.length && !loading ? (
        <div>
          {T.translate('generic.exportedStatuses.loading')}: {filesToUpload?.length}
        </div>
      ) : (
        <>
          <div>
            {' '}
            {T.translate('generic.exportedStatuses.total')}: {filesToUpload?.length}
            <span>
              {' '}
              {T.translate('generic.exportedStatuses.success')}: {successful}
            </span>
            {failed > 0 && (
              <span>
                {' '}
                {T.translate('generic.exportedStatuses.failed')}: {failed}
              </span>
            )}
            {duplicates > 0 && (
              <span>
                {' '}
                {T.translate('generic.exportedStatuses.duplicates')}: {failed}
              </span>
            )}
          </div>
          {_.filter(filesToUpload, file => file?.error && file?.error?.body).length > 0 && (
            <MuiButton
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => {
                exportCSV();
              }}
            >
              {T.translate('generic.exportFailedFiles')}
            </MuiButton>
          )}
        </>
      )}
    </div>
  );
};

type FileDropZoneProps = {
  folderId?: string;
  noInput?: boolean;
  showProgress?: boolean;
  showInfo?: boolean;
  confidentialityRing?: string;
  children: any;
  noDrop?: boolean;
  refLink?: any;
  onDropCustom?: any;
  onDragEnter?: any;
  uploadInnerFile?: any;
  setUploadInnerFile?: any;
};

export default ({
  folderId,
  noInput,
  showProgress,
  showInfo,
  confidentialityRing,
  children,
  noDrop,
  refLink,
  onDropCustom,
  onDragEnter,
  uploadInnerFile,
  setUploadInnerFile,
}: FileDropZoneProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState({ pathname: '' });
  const dispatch = useDispatch();
  const uploadFilesProgressClosed = useSelector(
    (state: any) => state.case.uploadFilesProgressClosed,
  );
  const filesToUpload = useSelector((state: any) => state.case.uploadFiles);

  const uploadFilesPending = useSelector((state: any) => state.case.uploadFilesPending);
  const uploadFilesFeedback = useSelector((state: any) => state.case.uploadFilesFeedback);

  const showProgressLayer =
    !uploadFilesProgressClosed && (uploadFilesPending || filesToUpload.length > 0);

  const [{ canDrop /* , isOver */ } /*, drop */] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    // drop: () => ({ name: 'Dustbin' }),
    collect: (monitor: any) => ({
      // isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const showModal = (location: any) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const hideModal = () => setModalVisible(false);

  const handleBlockedRoute = (nextLocation: any) => {
    showModal(nextLocation);
    return false;
  };

  const handleConfirmNavigationClick = () => {
    dispatch(closeUploadFilesProgress());
    hideModal();
    history.push(lastLocation.pathname);
  };

  if (noDrop) return children;

  return (
    <Dropzone
      ref={refLink}
      noClick={true}
      onDrop={acceptedFiles => {
        if (onDropCustom) onDropCustom(acceptedFiles);
        else {
          if (uploadInnerFile) {
            dispatch(uploadFiles(acceptedFiles, folderId, uploadInnerFile));
            setUploadInnerFile(false);
          } else dispatch(uploadFiles(acceptedFiles, folderId));
        }
      }}
      onDragEnter={onDragEnter}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <React.Fragment>
            {children}
            {showProgress && showProgressLayer ? (
              <section className="case-file-drop-zone">
                <Prompt when={!modalVisible} message={handleBlockedRoute} />
                <div className="drag-layer upload-layer">
                  <div className="header">
                    <div className="file-stats-wrapper">
                      <div>
                        {uploadFilesPending
                          ? T.translate('generic.fileUploadInProgress')
                          : T.translate('generic.fileUploadHasBeenCompleted', {
                              status: T.translate(
                                uploadFilesFeedback ? 'generic.unsuccessful' : 'generic.successful',
                              ),
                            })}
                      </div>
                      <FileStatusRender
                        filesToUpload={filesToUpload}
                        loading={!uploadFilesPending}
                      />
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => dispatch(dismissUploadFilesFeedback())}
                        disabled={uploadFilesPending}
                      >
                        {T.translate('generic.close')}
                      </Button>
                    </div>
                  </div>
                  <div className="body">
                    {filesToUpload.map((file: any, index: number) => (
                      <FileProgress key={index} {...file} />
                    ))}
                  </div>
                </div>
                <Modal
                  show={modalVisible}
                  title={T.translate('generic.areYouSure')}
                  handleClose={hideModal}
                  saveTitle={T.translate('generic.yes')}
                  handleSave={handleConfirmNavigationClick}
                >
                  <div>{T.translate('generic.reviewFilesUploadedMessage')}</div>
                </Modal>
              </section>
            ) : null}
            <section
              style={{ pointerEvents: !canDrop ? 'none' : 'all' }}
              className="case-file-drop-zone"
            >
              <div
                className={classnames('drag-layer', {
                  drag: canDrop,
                  'drag-over': isDragActive,
                })}
                {...getRootProps()}
              >
                {!noInput && <input {...getInputProps()} />}
                {showInfo && canDrop ? (
                  <div
                    className={classnames(confidentialityRing ? 'border-box-modal' : 'border-box')}
                  >
                    <div>
                      <p>
                        <FontAwesomeIcon icon={faCloudUploadAlt} />
                        {confidentialityRing
                          ? T.translate('case.dropHereRingFiles', {
                              //@ts-ignore
                              ring: confidentialityRingCategoryLabel[confidentialityRing],
                            })
                          : T.translate('case.dropHere')}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
          </React.Fragment>
        );
      }}
    </Dropzone>
  );
};
