import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import classnames from 'classnames';
import { CircularProgress, Grow, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in ref={ref} {...props} />;
});

const DialogTitle = props => {
  const { children, classes, title, closeButton, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other} sx={{ p: '1rem' }}>
      {title && <Typography variant="h5">{title}</Typography>}
      {closeButton ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
            color: 'grey',
            mt: '-0.2rem',
            mr: '-0.7rem',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const dialogStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  paper: {
    height: '100%',
  },
});

const Modal = ({
  loadingBtn = false,
  disableOnOutsideClick = true,
  className = undefined,
  show,
  handleClose,
  title,
  disableClose = undefined,
  children,
  buttons,
  handleSave,
  disableSave = false,
  saveTitle,
  closeTitle = undefined,
  wide = undefined,
  hintContent = undefined,
  draggableDialog = false,
  fullHeight = false,
}) => {
  const classes = dialogStyles();
  useEffect(() => {
    const listener = event => {
      if (show && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        if (disableSave) !buttons && handleClose && handleClose();
        else {
          event.preventDefault();
          event.stopPropagation();
          handleSave && handleSave();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [show, handleSave, buttons, disableSave, handleClose]);

  return (
    <Dialog
      className={classnames(className)}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      PaperProps={{ classes: { ...(fullHeight && { root: classes.paper }) } }}
      open={show}
      fullWidth
      maxWidth={wide ? 'lg' : 'md'}
      TransitionComponent={Transition}
      transitionDuration={400}
      onClose={(event, reason) => {
        if (disableOnOutsideClick && reason === 'backdropClick') {
          return;
        }
        handleClose();
      }}
      PaperComponent={draggableDialog ? PaperComponent : null}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        id="draggable-dialog-title"
        title={title}
        closeButton={!disableClose}
        onClose={handleClose}
        style={{ ...(draggableDialog && { cursor: 'move' }) }}
      />
      <MuiDialogContent dividers sx={{ p: '1.5rem' }}>
        {children}
      </MuiDialogContent>
      <MuiDialogActions sx={{ p: '1rem' }}>
        {hintContent && (
          <Tooltip arrow placement="top" title={hintContent}>
            <InfoIcon style={{ color: '#7BA0F4' }} />
          </Tooltip>
        )}
        {!disableClose && (
          <Button
            type="button"
            variant="contained"
            color="inherit"
            onClick={handleClose}
            sx={{
              color: '#fff',
              bgcolor: 'text.disabled',
              ':hover': { bgcolor: 'text.secondary' },
            }}
          >
            {closeTitle || T.translate('generic.close')}
          </Button>
        )}
        {buttons}
        {handleSave && (
          <Button
            type="submit"
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={disableSave || loadingBtn}
          >
            {loadingBtn && <CircularProgress size={17} color="info" />}
            {!loadingBtn && (saveTitle || T.translate('generic.save'))}
          </Button>
        )}
      </MuiDialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  saveTitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  disableClose: PropTypes.bool,
  disableSave: PropTypes.bool,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};
Modal.defaultProps = {};

export default Modal;
