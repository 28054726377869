import React, { useEffect, useState } from 'react';
import * as actions from '../redux/actions';
import * as actionsV from 'features/viewing/redux/actions';
import T from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'utils/hooks';
import history from 'common/history';
import { withoutTrailingSlash } from 'utils/path';
import { Modal } from 'features/common/MaterialBasedComponents';
import { diff } from 'deep-object-diff';
import {
  selectCurrentFolder,
  selectDocuments,
  selectPermissions,
  selectMinIdWidth,
  selectCaseDataRoomSettings,
  selectUserDataRoomSettings,
  selectParams,
  selectCurrentFolderAgreed,
  selectDeepSearch,
  selectWiderColumns,
  selectWiderDateColumn,
  selectCurrentCaseId,
  selectClickEnterToPresent,
  selectDocSearchVal,
  selectIsDeepSearchPerformed,
  selectAISearch,
  selectPreserveAttchedFileSortOrder,
  selectUseOnlyIdColForPresentMode,
  selectFetchDocumentsPending,
  selectDisableSearchFromAllDocs,
  selectDocFinderFilter,
} from 'common/selectors';
import Table, { filterByGlobalPage, filterByLocalPage, getStringValue } from '../../common/Table';
import { createSelector } from 'reselect';
import { setConfig } from 'react-hot-loader';
import { getSortFunction, sortDocumentsWithAttachments } from 'features/common/Table/utils';
import columnsSelector from './columnsSelector';
import useSearchContext from 'features/common/Search/useSearchContext';
import equal from 'react-fast-compare';

setConfig({ trackTailUpdates: false }); // without this it's causing infinite hot-reloading due to useeffect with async call

const filterByColumns = [
  'id',
  'type',
  'name',
  'originalName',
  'folderSpecificName',
  'discoveryId',
  'externalId',
  'description',
  'notes',
  // {
  //   id: 'createdBy',
  //   accessor: ({ createdBy }: any) =>
  //     createdBy &&
  //     `${moment(createdBy.date)
  //       .utc()
  //       .format('DD/MM/YYYY HH:mm')} ${createdBy.name}`,
  // },
  // {
  //   id: 'addedBy',
  //   accessor: ({ addedBy }: any) =>
  //     addedBy &&
  //     `${moment(addedBy.date)
  //       .utc()
  //       .format('DD/MM/YYYY HH:mm')} ${addedBy.name}`,
  // },
  // {
  //   id: 'lastEditedBy',
  //   accessor: ({ lastEditedBy }: any) =>
  //     lastEditedBy &&
  //     `${moment(lastEditedBy.date)
  //       .utc()
  //       .format('DD/MM/YYYY HH:mm')} ${lastEditedBy.name}`,
  // },
  // {
  //   id: 'assignedTo',
  //   accessor: ({ assignedTo }: any) =>
  //     assignedTo && assignedTo.map((item: any) => T.translate(`case.${item}`)).join(', '),
  // },
  // {
  //   id: 'locations',
  //   accessor: ({ locations }: any) => locations && locations.join(', '),
  // },
  {
    id: 'bundleLocations',
    accessor: ({ bundleLocations }: any) =>
      bundleLocations && bundleLocations.map((item: any) => item.fileName),
  },
];

const onDoubleClickHandlerSelector = createSelector(
  (present?: 'private' | 'public') => present,
  (_: any, useCompositeKey: any) => useCompositeKey,
  (_: any, __: any, docSearchVal: any) => docSearchVal,
  (_: any, __: any, ___: any, setDocSearchVal: any) => setDocSearchVal,
  (_: any, __: any, ___: any, ____: any, dispatch: any) => dispatch,
  (present, useCompositeKey, docSearchVal, setDocSearchVal, dispatch: any) => (row: any) => {
    history.push(
      history.location.pathname.replace(/\/files.*/, ``) +
        `/files/${row.id}${useCompositeKey ? `/compositeKey/${row.compositeKey}` : ``}${
          !!present ? `?present=${present}` : ''
        }`,
    );
    if (docSearchVal) setDocSearchVal('');
    dispatch(actions.setCurrentSelectedFile(null));
  },
);

const updateSortHandlerSelector = createSelector(
  (dispatch: any) => dispatch,
  (_: any, currentFolder: any) => currentFolder,
  (dispatch, currentFolder) => (index: any, ids: any) => {
    dispatch(
      actions.updateDocumentSort({
        index,
        ids,
        zeroBasedIndex: currentFolder.zeroBasedIndex,
        agreed: currentFolder.agreed,
      } as any),
    );
  },
);

const updateDocumentSortSelector = createSelector(
  (
    dispatch: any,
    documents: any,
    currentFolder: any,
    setShowModal: any,
    preserveAttchedFileSortOrder: any,
  ) => ({
    dispatch,
    documents,
    currentFolder,
    setShowModal,
    preserveAttchedFileSortOrder,
  }),
  ({ dispatch, documents, currentFolder, setShowModal, preserveAttchedFileSortOrder }: any) => (
    sortByColumn: any,
    sortDirection: any,
    onCustomSort: any,
  ) => {
    const fn = () => {
      dispatch(actions.modifyDocumentSortSelection({ sortByColumn, sortDirection } as any));
      const sortFunc = getSortFunction(sortByColumn, sortDirection, onCustomSort);
      const sortedData =
        preserveAttchedFileSortOrder && sortFunc
          ? sortDocumentsWithAttachments(documents, sortFunc)
          : documents.slice().sort(sortFunc);
      const ids = sortedData.map(({ id }: any) => id);
      if (!equal(sortedData, documents))
        dispatch(
          actions.updateDocumentSort({
            ids,
            zeroBasedIndex: currentFolder.zeroBasedIndex,
            agreed: currentFolder.agreed,
            sortDirection,
            sortByColumn,
          } as any),
        );
    };
    if (setShowModal) setShowModal({ fn });
    else fn();
  },
);

const additionalDragInfoSelector = createSelector(selectCurrentFolder, ({ code }) => ({
  folderCode: code,
}));

const findPageByFilterTerm = (doc: any, filterVal: any) => {
  const startPage = doc.startPage && doc.startPage.split('.');
  const lpSearch = filterVal && /^[0-9]{3,6}[/][0-9]{1,7}?$/.test(filterVal);
  const gpSearch = !doc.highlights && filterVal && /^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filterVal);
  const searchItem = gpSearch && filterVal && filterVal.length > 0 && filterVal;
  const searchIdx =
    (searchItem &&
      doc &&
      doc.globalPagePrefix &&
      searchItem.toLowerCase().replace(doc.globalPagePrefix.toLowerCase(), '')) ||
    (lpSearch && filterVal && filterVal.split('/')[1]) ||
    '';
  const goToPage =
    (gpSearch &&
      searchIdx.includes('.') &&
      startPage.length > 1 &&
      parseInt(searchIdx.split('.')[1], 10) - parseInt(startPage[1], 10) + 1) ||
    (gpSearch && parseInt(searchIdx, 10) - parseInt(doc.startPage, 10) + 1) ||
    (lpSearch && parseInt(searchIdx, 10)) ||
    1;
  return goToPage;
};

const filterAndPresent = (allDocs: any, filterVal: any, setGoToPageNumber: any) => {
  const filteredData = allDocs.filter((row: any) => {
    if (/^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filterVal)) return filterByGlobalPage(row, filterVal);
    else if (/^[0-9]{3,6}[/][0-9]{1,7}?$/.test(filterVal))
      return filterByLocalPage(row, filterVal.split('/'));
    else {
      return getStringValue(row, filterByColumns[0])
        .toLowerCase()
        .includes(filterVal.toLowerCase());
    }
  });
  if (filteredData.length > 0) {
    const goToPage = findPageByFilterTerm(filteredData[0], filterVal);
    history.push(
      history.location.pathname.replace(/\/files.*/, ``) +
        `/files/${filteredData[0].id}/compositeKey/${filteredData[0].compositeKey}?present=public`,
    );
    setGoToPageNumber && setGoToPageNumber(goToPage);
  }
};

const TableCase = React.memo(
  React.forwardRef(
    (
      {
        onRowSelected,
        isAllDocuments,
        isQuickLinksPage,
        isPresentModePage,
        isPresentModeWithBackEnd,
        onFilterChanged,
        onFilterCategoryChanged,
        isSearchModePage,
        isAiToolsPage,
        isDocFinderPage,
        setGridList,
      }: any,
      ref,
    ) => {
      const defaultFilters = {
        term: '',
        goToGlobalPageIdx: '',
        authors: [],
        recipients: [],
        dateFrom: null,
        dateTo: null,
        dateFilter: false,
        withAnnotations: false,
        withoutAnnotations: false,
        shared: false,
        notShared: false,
        public: false,
        private: false,
        withPublicHyperlinks: false,
        withoutPublicHyperlinks: false,
        trialBundles: [],
      };

      const { searchHandler, searchFunctionsHandler, clearSearch } = useSearchContext();
      const [isFetchDocInitiated, setIsFetchDocInitiated] = useState(false);
      const [sortMode, setSortMode] = useState(false);
      const [filter, setFilter] = useState(defaultFilters);
      const [showModal, setShowModal] = useState<any>(undefined);
      const [key, setKey] = useState(Math.random());
      const fetchDocumentsPending = useSelector(selectFetchDocumentsPending);
      const currentFolder = useSelector(selectCurrentFolder);
      const prevCurrentFolder = usePrevious(currentFolder) as any;
      const documents = useSelector(selectDocuments) as Array<any>;
      const totalDocumentsCount = documents.length;
      const permissions = useSelector(selectPermissions);
      const userDataRoomSettings = useSelector(selectUserDataRoomSettings);
      const caseDataRoomSettings = useSelector(selectCaseDataRoomSettings);
      const { file, compositeKey }: any = useSelector(selectParams);
      const present = useSelector(({ matcher: { query = {} } }: any) => query.present);
      const additionalDragInfo = useSelector(additionalDragInfoSelector);
      const minIdWidth = useSelector(selectMinIdWidth);
      const isTrialBookAgreed = useSelector(selectCurrentFolderAgreed);
      const isReadOnlyTrialbook = currentFolder.readOnly;
      const useOnlyIdColForPresentMode = useSelector(selectUseOnlyIdColForPresentMode);
      const isDisableSearchFromAllDocs = useSelector(selectDisableSearchFromAllDocs);
      const showDeepSearch =
        useSelector(selectDeepSearch) &&
        ((isAllDocuments && !isDisableSearchFromAllDocs) ||
          isSearchModePage ||
          isDocFinderPage ||
          isPresentModeWithBackEnd);
      const showGoToPageFilter = currentFolder && currentFolder.globalPaging;
      const isWiderColumns = useSelector(selectWiderColumns);
      const isWiderDateColumn = useSelector(selectWiderDateColumn);
      const currentCaseId = useSelector(selectCurrentCaseId);
      const isClickEnterToPresent = useSelector(selectClickEnterToPresent);
      const dispatch = useDispatch();
      const currentUrlHashVal = window.location.hash;
      const hashFileId = currentUrlHashVal.split('#')[1];
      const isTagsFolder = currentFolder && currentFolder.type === 'quicklinks';
      const docSearchVal = useSelector(selectDocSearchVal);
      const isDeepSearchPerformed = useSelector(selectIsDeepSearchPerformed);
      const isAISearch = useSelector(selectAISearch) && isAiToolsPage;
      const preserveAttchedFileSortOrder = useSelector(selectPreserveAttchedFileSortOrder);
      const isIndexFolder =
        currentFolder &&
        currentFolder.name === 'index' &&
        currentFolder.type === 'trialbook' &&
        currentFolder.readOnly;
      const hasDocFinderFilter = useSelector(selectDocFinderFilter);

      const filterByTerm = (term: any) => {
        setFilter(prev => ({
          ...prev,
          term: term ? term : '',
        }));
        onRowSelected && onRowSelected([]);
      };

      const filterByOptions = (category: any) => {
        setFilter(prev => ({ ...prev, ...category }));
        onRowSelected && onRowSelected([]);
        onFilterCategoryChanged && onFilterCategoryChanged(category);
      };

      const setGoToPageNumber = (pageNo: any) => dispatch(actionsV.setGoToPageNumber(pageNo));

      const setDocSearchVal = (val: any) => {
        if (isSearchModePage && docSearchVal !== val) dispatch(actionsV.setDocSearch(val));
        else if (!isSearchModePage && docSearchVal) dispatch(actionsV.setDocSearch(''));
      };

      const presentFirstDoc = (allDocs: any, val: any) => {
        if (!fetchDocumentsPending && allDocs.length > 0) {
          const goToPage = findPageByFilterTerm(allDocs[0], val);
          history.push(
            history.location.pathname.replace(/\/files.*/, ``) +
              `/files/${allDocs[0].id}?present=public`,
          );
          setGoToPageNumber && setGoToPageNumber(goToPage);
        }
      };

      const clearSearchedFiles = (allDocs: any) => {
        if (allDocs.length > 0) {
          dispatch(actions.clearDocuments());
        }
      };

      const onMount = () => {
        searchFunctionsHandler &&
          ((isDocFinderPage && !hasDocFinderFilter) || !isDocFinderPage) &&
          searchFunctionsHandler({
            searchChanged: filterByTerm,
            filterChanged: filterByOptions,
            deepSearchChanged: (val: any) => {
              isAISearch
                ? dispatch(actions.fetchMatches({ searchTerm: val }))
                : dispatch(
                    actions.fetchSearchedFiles({
                      searchTerm: val,
                      isDocFinderPage: isDocFinderPage || isPresentModeWithBackEnd,
                      isSearchModePage,
                    }),
                  );
            },
            presentToHearingRoom: (allDocs: any, val: any) =>
              val && filterAndPresent(allDocs, val, setGoToPageNumber),
            presentFirstDoc,
            clearSearchedFiles,
          });
      };

      // MOUNT
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(onMount, []);

      useEffect(() => {
        return (
          searchHandler &&
          searchHandler(
            ((isDocFinderPage && !hasDocFinderFilter) || !isDocFinderPage) && {
              isReady: !sortMode,
              placeholder: showDeepSearch ? T.translate('case.search') : T.translate('case.filter'),
              showDeepSearch,
              isPresentModePage,
              isSearchModePage,
              isDocFinderPage,
              isPresentModeWithBackEnd,
              isClickEnterToPresent,
              useOnlyIdColForPresentMode,
              isAllDocsWithLongList: totalDocumentsCount > 8000,
              showGoToPageFilter,
              selectedColumns: file
                ? (userDataRoomSettings && userDataRoomSettings.columnsSmallView) ||
                  (caseDataRoomSettings && caseDataRoomSettings.columnsSmallView)
                : (userDataRoomSettings && userDataRoomSettings.columns) ||
                  (caseDataRoomSettings && caseDataRoomSettings.columns),
              documents,
            },
          )
        );
      }, [
        sortMode,
        searchHandler,
        showDeepSearch,
        showGoToPageFilter,
        isPresentModePage,
        isPresentModeWithBackEnd,
        isClickEnterToPresent,
        useOnlyIdColForPresentMode,
        userDataRoomSettings,
        caseDataRoomSettings,
        documents,
        isSearchModePage,
        isDocFinderPage,
        file,
        totalDocumentsCount,
      ]);

      useEffect(() => {
        const unlisten = history.listen((e: any) => {
          const withoutFilesPart = (url: any) => url.replace(/\/files\/.*$/, '');
          const uriCurrentFolder = /[^/]*$/.exec(
            withoutFilesPart(withoutTrailingSlash(e.pathname)),
          )?.[0];

          if (
            (currentFolder && currentFolder.code && uriCurrentFolder !== currentFolder.code) ||
            (Object.keys(currentFolder).length === 0 &&
              !e.pathname.includes(`${currentCaseId}/all-documents`) &&
              !e.pathname.includes(`${currentCaseId}/search-mode`) &&
              !e.pathname.includes(`${currentCaseId}/present-mode`) &&
              !e.pathname.includes(`${currentCaseId}/docFinder`) &&
              (isAllDocuments ||
                isPresentModePage ||
                isSearchModePage ||
                isDocFinderPage ||
                isPresentModeWithBackEnd)) ||
            (e.pathname.includes(`${currentCaseId}/all-documents`) && !isAllDocuments) ||
            (e.pathname.includes(`${currentCaseId}/present-mode`) &&
              (isPresentModeWithBackEnd ? !isPresentModeWithBackEnd : !isPresentModePage)) ||
            (e.pathname.includes(`${currentCaseId}/search-mode`) && !isSearchModePage) ||
            (e.pathname.includes(`${currentCaseId}/docFinder`) && !isDocFinderPage)
          ) {
            setSortMode(false);
            setShowModal(undefined);
            setKey(Math.random());
            setIsFetchDocInitiated(false);
            clearSearch && clearSearch();
            dispatch(actions.clearDocuments());
            dispatch(actions.setFilterOptions(null));
            dispatch(actions.setCurrentSelectedFile(null));
          }
        });
        if (
          !isFetchDocInitiated &&
          clearSearch &&
          (isAllDocuments ||
            isPresentModePage ||
            (currentFolder && currentFolder.id !== prevCurrentFolder.id))
        ) {
          dispatch(
            actions.fetchDocuments({
              ...(isPresentModePage && { presentMode: true }),
            }),
          );

          setIsFetchDocInitiated(true);
        }
        return unlisten;
      }, [
        dispatch,
        isAllDocuments,
        clearSearch,
        isQuickLinksPage,
        isPresentModePage,
        isPresentModeWithBackEnd,
        currentCaseId,
        currentFolder,
        isSearchModePage,
        isDocFinderPage,
        prevCurrentFolder,
        isFetchDocInitiated,
      ]);

      useEffect(() => {
        if (currentFolder.type === 'quicklinks') return;
        // hashFileId is used for auto scrolling and selecting file -> when you select file location from full size viewer
        if (file || hashFileId) {
          documents.forEach((doc: any) => {
            const shouldSelectDocument =
              isPresentModePage || isIndexFolder
                ? doc.compositeKey === compositeKey
                : hashFileId && hashFileId.includes('TV')
                ? doc.id === hashFileId
                : doc.id === file;
            if (shouldSelectDocument) {
              doc.selected = true;
            } else if (
              ((isPresentModePage || isIndexFolder) && present) ||
              isSearchModePage ||
              isAllDocuments
            ) {
              doc.selected = false;
            }
          });

          onRowSelected && onRowSelected(documents.filter(({ selected }: any) => !!selected));
        }
      }, [
        file,
        hashFileId,
        documents,
        onRowSelected,
        currentFolder.type,
        compositeKey,
        isPresentModePage,
        isIndexFolder,
        present,
        isSearchModePage,
        isAllDocuments,
      ]);

      const updateDocumentSort = updateDocumentSortSelector(
        dispatch,
        documents,
        currentFolder,
        typeof showModal === 'undefined' && setShowModal,
        preserveAttchedFileSortOrder,
      );

      const updateSortHandler = updateSortHandlerSelector(dispatch, currentFolder);
      const onDoubleClickHandler = onDoubleClickHandlerSelector(
        present,
        isPresentModePage || isIndexFolder,
        docSearchVal,
        setDocSearchVal,
        dispatch,
      );

      const selectedColumns =
        (userDataRoomSettings && userDataRoomSettings.columns) || caseDataRoomSettings.columns;

      const configureColumns = (columns: any) =>
        columns && [
          ...columns,
          ...(isQuickLinksPage && isTagsFolder
            ? ['viewed', 'dateViewed']
            : showGoToPageFilter || isPresentModePage
            ? ['globalPages']
            : []),
        ];

      const selectedColumnsSmallView =
        (userDataRoomSettings && userDataRoomSettings.columnsSmallView) ||
        (caseDataRoomSettings && caseDataRoomSettings.columnsSmallView) ||
        undefined;

      const tableRef = React.useRef<any>(null);

      const columns = columnsSelector(
        file,
        configureColumns(selectedColumns),
        configureColumns(selectedColumnsSmallView),
        permissions,
        sortMode,
        setSortMode,
        updateDocumentSort,
        minIdWidth,
        isTrialBookAgreed,
        isReadOnlyTrialbook,
        isQuickLinksPage,
        showGoToPageFilter,
        present,
        filter,
        currentFolder,
        isWiderDateColumn,
        isWiderColumns,
        isPresentModePage,
        isPresentModeWithBackEnd,
        isTagsFolder,
        setGoToPageNumber,
        setDocSearchVal,
        tableRef,
      );

      const actualFilter = { ...diff(defaultFilters, filter) };

      return (
        <>
          <Table
            key={key}
            ref={tableRef}
            filter={actualFilter}
            onRowSelected={onRowSelected}
            data={documents}
            columns={columns}
            filterByColumns={
              isPresentModePage && useOnlyIdColForPresentMode
                ? ['id']
                : isPresentModePage
                ? ['id', 'name', 'discoveryId']
                : filterByColumns
            }
            sortMode={sortMode}
            updateSort={updateSortHandler}
            onDoubleClick={onDoubleClickHandler}
            additionalDragInfo={additionalDragInfo}
            onFilterChanged={onFilterChanged}
            defaultMessage={
              isSearchModePage
                ? isDeepSearchPerformed
                  ? T.translate('case.searchNoMatch')
                  : T.translate('case.searchModePageDefaultMsg')
                : isDocFinderPage || isPresentModeWithBackEnd
                ? isDeepSearchPerformed
                  ? T.translate('case.searchNoMatch')
                  : T.translate('case.docFinderPageDefaultMsg')
                : ''
            }
            setGridList={setGridList}
          />
          <Modal
            show={!!showModal}
            handleSave={() => {
              setShowModal(false);
              showModal?.fn();
            }}
            handleClose={() => setShowModal(undefined)}
            title={T.translate('generic.areYouSure')}
            saveTitle={T.translate('generic.yes')}
          >
            {T.translate('case.areYouSureSort')}
          </Modal>
        </>
      );
    },
  ),
);

export default TableCase;
