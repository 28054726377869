import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import StickyDropdown from 'features/common/StickyDropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId } from 'common/selectors';
import history from 'common/history';

// className gets mapped onto it, keep it
const CaseSettingsButton = memo(({ className, isTeamAdmin, isSystemAdmin, isCaseAdmin }) => {
  const currentCaseId = useSelector(selectCurrentCaseId);

  const title = (
    <div className="d-inline-flex">
      <FontAwesomeIcon icon={faCog} alt={faCog.iconName} />
      <p className="d-none ml-3">{T.translate('generic.settings')}</p>
    </div>
  );

  const items = (
    <React.Fragment>
      {isSystemAdmin && !isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/case-admin/case-settings`,
            )
          }
        >
          <p>{T.translate('case.caseSettings')}</p>
        </NavDropdown.Item>
      )}
      {isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/case-admin`,
            )
          }
        >
          <p>{T.translate('case.caseAdmin')}</p>
        </NavDropdown.Item>
      )}
      {isTeamAdmin && !isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/team-admin`,
            )
          }
        >
          <p>{T.translate('case.teamAdmin')}</p>
        </NavDropdown.Item>
      )}
      <NavDropdown.Item
        onClick={() =>
          history.push(
            history.location.pathname.replace(/\/case.*/, ``) +
              `/case/${currentCaseId}/settings/role-settings`,
          )
        }
      >
        <p>{T.translate('case.roleSettings')}</p>
      </NavDropdown.Item>
    </React.Fragment>
  );

  return (
    <div className={className}>
      <StickyDropdown alignRight title={title} className="d-none">
        {items}
      </StickyDropdown>
      <NavDropdown id="user" alignRight title={title} className="d-l-none">
        {items}
      </NavDropdown>
    </div>
  );
});

CaseSettingsButton.propTypes = {};
CaseSettingsButton.defaultProps = {};

export default CaseSettingsButton;
