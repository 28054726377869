import { call, put, take, takeLatest } from 'redux-saga/effects';

import {
  CASE_DOWNLOAD_FOLDER_BEGIN,
  CASE_DOWNLOAD_FOLDER_SUCCESS,
  CASE_DOWNLOAD_FOLDER_FAILURE,
  CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const downloadFolder = createPromiseAction(CASE_DOWNLOAD_FOLDER_BEGIN);

export function dismissDownloadFolderFeedback() {
  return {
    type: CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doDownloadFolder(action) {
  const {
    payload: { caseId, folderId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/folders/${folderId}/zip-files`);

  if (res && res.error) {
    yield put({
      type: CASE_DOWNLOAD_FOLDER_FAILURE,
      feedback: {
        message: 'feedback.downloadFolderFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DOWNLOAD_FOLDER_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
  return;
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDownloadFolder() {
  yield takeLatest(downloadFolder, withCurrentCaseId(doDownloadFolder));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DOWNLOAD_FOLDER_BEGIN + '.TRIGGER':
      return {
        ...state,
        downloadFolderPending: true,
        downloadFolderFeedback: null,
      };

    case CASE_DOWNLOAD_FOLDER_SUCCESS:
      return {
        ...state,
        downloadFolderPending: false,
        downloadFolderFeedback: action.feedback,
      };

    case CASE_DOWNLOAD_FOLDER_FAILURE:
      return {
        ...state,
        downloadFolderPending: false,
        downloadFolderFeedback: action.feedback,
      };

    case CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        downloadFolderFeedback: null,
      };

    default:
      return state;
  }
}
