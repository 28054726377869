import React, { useEffect } from 'react';
import T from 'i18n';
import { Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, isValid } from 'date-fns';
import logger from 'utils/logger';
import ThreeStateToggle from 'features/common/Filters/ThreeStateToggle';
import LookupMui from 'features/common/MaterialBasedComponents/Lookup';
import { useDispatch } from 'react-redux';
import { fetchAuthorsLookup } from 'features/viewing/redux/fetchAuthorsLookup';
import { fetchRecipientsLookup } from 'features/viewing/redux/fetchRecipientsLookup';
import { fetchCreatedByLookup } from 'features/viewing/redux/fetchCreatedByLookup';
import debounce from 'utils/debounce';
import { fetchDocumentsDocFinder } from '../redux/fetchDocumentsDocFinder';

type FilterProps = {
  filterCategory: any;
  onFilterCategoryChanged: any;
};

const Lookup = ({ field, placeholder, label, fetch }: any) => {
  return (
    <>
      <Grid xs={12}>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {label}
        </Typography>
      </Grid>
      <LookupMui
        {...field}
        fetchFunction={fetch}
        minInputLength={2}
        placeholder={placeholder}
        variant="standard"
        multiple
        inline
      />
    </>
  );
};

export default ({ filterCategory, onFilterCategoryChanged }: FilterProps) => {
  const {
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      search: '',
      dateFrom: null,
      dateTo: null,
      author: undefined,
      recipient: undefined,
      // createdBy: undefined,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = debounce((data: any) => dispatch(fetchDocumentsDocFinder(data)), 250);

  const handleUserTypedDate = (field: any) => (event: any) => {
    const typedDate = event.target.value;
    const parsedDate = parse(typedDate, 'dd/MM/yyyy', new Date());
    if (isValid(parsedDate)) {
      // handleDateChange(field)(parsedDate);
    } else {
      logger.ERROR('Invalid date format.');
    }
  };

  const handleThreeStateToggle = (value: string, values: Array<string>) => {
    if (value === values[0]) {
      // handleToggle({ target: { name: values[0], checked: true } });
    } else if (value === values[1]) {
      // handleToggle({ target: { name: values[1], checked: true } });
    } else {
      // handleToggle({ target: { name: values[0], checked: false } });
    }
  };
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid xs={12}>
          <Typography
            style={{
              marginLeft: 0,
              alignSelf: 'center',
              marginTop: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {T.translate('case.search')}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                style={{ width: '100%' }}
                placeholder="Look up by file name, Doc ID, Global Page or other meta data"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid xs={12}>
            <Typography
              style={{
                marginLeft: 0,
                alignSelf: 'center',
                marginTop: '0.5rem',
                fontWeight: 'bold',
              }}
            >
              {T.translate('filters.dateFromTo')}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <div style={{ display: 'flex' }}>
              <Controller
                name="dateFrom"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    format="dd/MM/yyyy"
                    value={filterCategory?.dateFrom && new Date(filterCategory?.dateFrom)}
                    // onChange={handleDateChange('dateFrom')}
                    slotProps={{
                      textField: {
                        size: 'small',
                        autoComplete: 'off',
                        onBlur: handleUserTypedDate('dateFrom'),
                        variant: 'standard',
                        style: { flexGrow: 1, padding: '3px 0' },
                        placeholder: T.translate('generic.from'),
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="dateTo"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    format="dd/MM/yyyy"
                    value={filterCategory?.dateTo && new Date(filterCategory?.dateTo)}
                    // onChange={handleDateChange('dateTo')}
                    slotProps={{
                      textField: {
                        size: 'small',
                        autoComplete: 'off',
                        onBlur: handleUserTypedDate('dateTo'),
                        variant: 'standard',
                        style: { marginLeft: '1rem', flexGrow: 1, padding: '3px 0' },
                        placeholder: T.translate('generic.to'),
                      },
                    }}
                  />
                )}
              />
            </div>
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="author"
          control={control}
          render={({ field }) => {
            return (
              <Lookup
                field={field}
                fetch={fetchAuthorsLookup}
                label={T.translate('filters.authors')}
                placeholder="Search by author's name"
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="recipient"
          control={control}
          render={({ field }) => {
            return (
              <Lookup
                field={field}
                fetch={fetchRecipientsLookup}
                label={T.translate('filters.recipients')}
                placeholder="Search by recipient's name"
              />
            );
          }}
        />
      </Grid>
      {/* <Grid item xs={6}>
        <Controller
          name="createdBy"
          control={control}
          render={({ field }) => {
            return (
              <Lookup
                field={field}
                fetch={fetchCreatedByLookup}
                label={T.translate('filters.createdBy')}
                placeholder="Search by created by's name"
              />
            );
          }}
        />
      </Grid> */}
      {/* <Divider style={{ width: '100%' }} /> */}
      {/* <Grid item xs={12}>
        <ThreeStateToggle
          label1={T.translate('filters.withoutAnnotations')}
          label2={T.translate('filters.allDocuments')}
          label3={T.translate('filters.withAnnotations')}
          style={{ padding: 0 }}
          defaultValue={
            filterCategory?.withAnnotations
              ? 'withAnnotations'
              : filterCategory?.withoutAnnotations
              ? 'withoutAnnotations'
              : 'null'
          }
          values={['withoutAnnotations', 'null', 'withAnnotations']}
          onChanged={value =>
            handleThreeStateToggle(value, ['withAnnotations', 'withoutAnnotations'])
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ThreeStateToggle
          label1={T.translate('filters.withoutPublicHyperlinks')}
          label2={T.translate('filters.withAllHyperlinks')}
          label3={T.translate('filters.withPublicHyperlinks')}
          style={{ padding: 0 }}
          defaultValue={
            filterCategory?.withoutPublicHyperlinks
              ? 'withoutPublicHyperlinks'
              : filterCategory?.withPublicHyperlinks
              ? 'withPublicHyperlinks'
              : 'null'
          }
          values={['withoutPublicHyperlinks', 'null', 'withPublicHyperlinks']}
          onChanged={value =>
            handleThreeStateToggle(value, ['withPublicHyperlinks', 'withoutPublicHyperlinks'])
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ThreeStateToggle
          label1={T.translate('privateOnly')}
          label2={T.translate('allAnnotations')}
          label3={T.translate('publicOnly')}
          style={{ padding: 0 }}
          defaultValue={
            filterCategory?.private ? 'private' : filterCategory?.public ? 'public' : 'null'
          }
          values={['private', 'null', 'public']}
          onChanged={value => handleThreeStateToggle(value, ['private', 'public'])}
        />
      </Grid> */}
    </Grid>
  );
};
