import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { objectValueFromPath } from 'utils/object';
import ErrorFeedback from 'features/common/ErrorFeedback';

const TextForm = React.forwardRef(
  (
    {
      placeholder,
      onChange,
      defaultValue,
      name,
      label,
      readOnly,
      value,
      type,
      autofocus,
      autocomplete,
      style,
      variant = 'outlined',
      rows = '2',
      margin = 'dense',
      shrink,
      fullWidth = true,
      multiline,
      errors,
    },
    ref,
  ) => {
    const firstInputRef = useRef();

    useEffect(
      () =>
        autofocus &&
        firstInputRef.current &&
        firstInputRef.current.focus &&
        firstInputRef.current.focus(),
      [autofocus, firstInputRef],
    );

    return (
      <>
        <TextField
          label={label}
          inputRef={el => {
            ref && ref(el);
            firstInputRef.current = el;
          }}
          InputLabelProps={{ shrink }}
          rows={rows}
          margin={margin}
          fullWidth={fullWidth}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          multiline={multiline}
          onChange={onChange}
          disabled={readOnly}
          value={value}
          type={type}
          autoComplete={autocomplete ? 'on' : 'off'}
          sx={style}
          variant={variant}
          error={errors && errors[name] ? true : false}
          helperText={errors && errors[name] && 'test'}
        />
      </>
    );
  },
);

export default TextForm;
