import { Grid, Typography } from '@mui/material';
import React from 'react';
import T from 'i18n';
import SearchInput from './SearchInput';
import SearchInfo from './SearchInfo';

interface NewSearchContainerProps {
  handleChangeFilters: Function;
  handleSearch: Function;
  locationsList: Array<any>;
  filters: any;
  searchContainerState: any;
}

export const NewSearchContainer = ({
  handleChangeFilters,
  handleSearch,
  locationsList,
  filters,
  searchContainerState,
}: NewSearchContainerProps) => {
  return (
    <>
      <Grid
        container
        height="100%"
        sx={{ alignContent: 'center', justifyContent: 'center', mt: '-4.5rem' }}
        xs={12}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ color: '#212529', fontWeight: 700 }}>
            {T.translate('case.enterTopicHEading')}
          </Typography>
          <SearchInfo isNewSearch={true} />
        </Grid>
        <Grid item xs={12}>
          <SearchInput
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            handleSearch={handleSearch}
            removeFolders={false}
            topic={searchContainerState['searchMatchesInput']?.phrase}
            locationsList={locationsList}
            isNewSearch={true}
          />
        </Grid>
      </Grid>
    </>
  );
};
