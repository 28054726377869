import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap/Table';
import classnames from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Row from './Row';
import HeaderRow from './HeaderRow';
import useDroppableBodyContanerContext from './useDroppableBodyContainerContext';
import { usePrevious } from 'utils/hooks';
import equal from 'react-fast-compare';
// //@ts-ignore
// import data from './data';

type DroppableBodyProps = {
  updateSort: (index: number, idsToUpdate: Array<string>) => void;
  columns: Array<any>;
  children: Array<JSX.Element>;
  data: Array<any>;
  sortMode: boolean;
  forwardedRef: any;
  onSortHandler: ({ sortByColumn, sortDirection, onCustomSort }: any) => void;
  sortedColumn: string;
  sortedDirection: 'asc' | 'desc' | null;
  readOnly?: boolean;
};

export default React.memo(({ children, readOnly, ...rest }: DroppableBodyProps) => {
  const {
    updateSort,
    columns,
    data,
    sortMode,
    forwardedRef,
    onSortHandler,
    sortedColumn,
    sortedDirection,
  } = useDroppableBodyContanerContext();

  const prevData = usePrevious(data);
  const [unselectable, setUnselectable] = useState(false);
  const [selectedRowIds, setSelectedRowsIds] = useState<any[]>([]);
  const [shiftPressedIndex, setShiftPressedIndex] = useState(null);
  const [hoverable, setHoverable] = useState(!readOnly);

  useEffect(() => {
    if (!equal(prevData, data)) {
      setSelectedRowsIds(
        data.reduce((filtered: any, item: any) => {
          if (!!item.selected) filtered.push(item.compositeKey || item.id);
          return filtered;
        }, []),
      );
    }
  }, [prevData, data]);

  const getNewChildren = () => {
    const newChildren = [] as Array<any>;

    const mapNewChildren = (oldChildren: Array<JSX.Element>) =>
      React.Children.forEach(oldChildren, element => {
        newChildren.push(
          React.cloneElement(element, {
            ...element.props,
            data: {
              ...element.props.data,
              selectedRowIds,
              setSelectedRowsIds,
              shiftPressedIndex,
              setShiftPressedIndex,
              hoverable,
            },
          }),
        );
      });
    mapNewChildren(children);

    return newChildren;
  };

  const handleDragEnd = (result: any) => {
    setHoverable(true);

    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) return;

    const parsedOutSelectedRowIds = JSON.parse(result.source.droppableId) || [];

    const idsToUpdate = [
      result.draggableId,
      ...parsedOutSelectedRowIds.filter(
        (selectedRowId: string) => selectedRowId !== result.draggableId,
      ),
    ];

    updateSort(result.destination.index, idsToUpdate);
  };

  const handleDragStart = () => {
    setHoverable(false);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <BootstrapTable
          {...rest}
          hover={hoverable}
          onMouseDown={(e: React.MouseEvent<HTMLTableElement>) =>
            e.shiftKey && setUnselectable(true)
          }
          onMouseUp={() => setUnselectable(false)}
          className={classnames({
            'no-selection': unselectable,
            sticky: true,
          })}
        >
          <HeaderRow
            columns={columns}
            onSort={onSortHandler}
            sortedColumn={sortedColumn}
            sortedDirection={sortedDirection}
          />
          <Droppable
            droppableId={JSON.stringify(
              data
                .filter(({ compositeKey, id }: any) => selectedRowIds.includes(compositeKey || id))
                .map(({ id }: any) => id),
            )}
            mode="virtual"
            renderClone={(draggableProvided: any, draggableSnapshot: any, rubric: any) => {
              const rowProps = {
                index: rubric.source.index,
                columns,
                row: data[rubric.source.index],
                sortMode,
                selected: true,
              };
              return (
                <table>
                  <tbody>
                    <Row
                      {...rowProps}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={{
                        ...draggableProvided.draggableProps.style,
                        ...(draggableSnapshot.isDragging && {
                          backgroundColor: 'rgba(0,0,0,.125)',
                        }),
                      }}
                      dragHandleProps={draggableProvided.dragHandleProps}
                    />
                    {selectedRowIds
                      .filter(
                        (selectedRowId: string) =>
                          selectedRowId !==
                          (data[rubric.source.index].compositeKey || data[rubric.source.index].id),
                      )
                      .map((selectedRowId: string, i: number) => {
                        const index = data.findIndex(
                          ({ compositeKey, id }: any) => (compositeKey || id) === selectedRowId,
                        );
                        const rowProps = {
                          key: `${selectedRowId}-${i}`,
                          index,
                          columns,
                          row: data[index],
                          sortMode,
                          selected: true,
                        };
                        return (
                          <Row
                            {...rowProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              ...(draggableSnapshot.isDragging && {
                                backgroundColor: 'rgba(0,0,0,.125)',
                              }),
                              top:
                                parseInt(
                                  ((draggableProvided.draggableProps.style as unknown) as any).top,
                                ) +
                                (i + 1) * 37 +
                                'px',
                            }}
                            dragHandleProps={draggableProvided.dragHandleProps}
                          />
                        );
                      })}
                  </tbody>
                </table>
              );
            }}
          >
            {(droppableProvided: any) => (
              <tbody
                ref={ref => {
                  if (forwardedRef.current) {
                    // eslint-disable-next-line react/no-find-dom-node
                    const scrollContainer = ReactDOM.findDOMNode(forwardedRef.current);
                    if (scrollContainer instanceof HTMLElement) {
                      droppableProvided.innerRef(scrollContainer);
                    }
                  } else {
                    droppableProvided.innerRef(ref);
                  }
                }}
                style={{ width: '100%', height: '100%' }}
              >
                {getNewChildren()}
              </tbody>
            )}
          </Droppable>
        </BootstrapTable>
      </DragDropContext>
    </>
  );
});
