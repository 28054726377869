import React from 'react';
import T from 'i18n';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { caseAdminModalActions } from './enums';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'features/common/MaterialBasedComponents';
import TextForm from 'features/common/TextForm';

const AddNewTeam = ({ show, action, handleSave, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        handleSave(action, data);
        handleClose();
      })}
      title={T.translate('case.addNewTeam')}
      saveTitle={T.translate('generic.add')}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <TextForm
                {...register('name', { required: true })}
                label={T.translate('case.teamName')}
                placeholder={T.translate('case.teamNamePlaceholder')}
                errors={errors}
                autofocus
              />
            </Col>
            <Col />
          </Row>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const RemoveTeam = ({ show, action, team, handleSave, handleClose }) => {
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={() => {
        handleSave(action, team.id);
        handleClose();
      }}
      title={T.translate('case.removeTeam')}
      saveTitle={T.translate('generic.remove')}
    >
      {T.translate('case.removeATeamMessage', {
        team: team.name,
      })}
    </Modal>
  );
};

const UpdateTeamName = ({ show, action, team, handleSave, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        const updatedTeam = {
          ...team,
          name: data.name,
        };
        handleSave(action, updatedTeam);
        handleClose();
      })}
      title={T.translate('case.updateTeamName')}
      saveTitle={T.translate('generic.update')}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <TextForm
                {...register('name', { required: true })}
                label={T.translate('case.teamName')}
                placeholder={T.translate('case.teamNamePlaceholder')}
                name="name"
                defaultValue={team.name}
                errors={errors}
                autofocus
              />
            </Col>
            <Col />
          </Row>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const CaseAdminModal = ({ show, action, team, handleClose, handleSave }) => {
  return (
    <>
      {action === caseAdminModalActions.addNewTeam && (
        <AddNewTeam show={show} action={action} handleClose={handleClose} handleSave={handleSave} />
      )}
      {action === caseAdminModalActions.updateTeamName && (
        <UpdateTeamName
          show={show}
          action={action}
          team={team}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
      {action === caseAdminModalActions.removeTeam && (
        <RemoveTeam
          show={show}
          action={action}
          team={team}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default CaseAdminModal;
