import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { Table } from 'features/common';
import { getSortFunction } from 'features/common/Table/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentFolder,
  selectParams,
  selectAnnotations,
  selectUserSettingAllAnnotations,
  selectMatchedUrl,
  selectCurrentCaseId,
  selectTimeline,
  selectDocSearchVal,
} from 'common/selectors';
import * as actions from '../redux/actions';
import history from 'common/history';
import { withoutTrailingSlash } from 'utils/path';
import { createSelector } from 'reselect';
import useSearchContext from 'features/common/Search/useSearchContext';
import { Modal } from 'features/common/MaterialBasedComponents';
import moment from 'moment';
import columnsSelector from './columnsSelector';
import * as actionsV from 'features/viewing/redux/actions';
import { useFetchTimeline } from '../redux/fetchTimeline';

const onDoubleClickHandlerSelector = createSelector(
  (present?: 'private' | 'public') => present,
  (_: any, isTimeline?: false) => isTimeline,
  (_: any, __: any, setDocSearchVal: any) => setDocSearchVal,
  (_: any, __: any, ___: any, dispatch: any) => dispatch,
  (present, isTimeline, setDocSearchVal: any, dispatch: any) => (row: any) => {
    isTimeline
      ? row.file &&
        row.file.id &&
        history.push(history.location.pathname.replace(/\/files.*/, ``) + `/files/${row.file.id}`)
      : history.push(
          history.location.pathname.replace(/\/files.*/, ``) +
            `/files/${row.fileId}/annotationId/${row.id}${!!present ? `?present=${present}` : ''}`,
        );
    if (isTimeline && row.file && row.file.id && row.fileText) {
      setDocSearchVal(row.fileText, row.location);
      setTimeout(() => dispatch(actions.setCurrentSelectedFile(row)), 0);
    }
    if (isTimeline && row.file && row.file.page)
      dispatch(actionsV.setGoToPageNumber(parseInt(row.file.page)));
  },
);

const updateSortHandlerSelector = createSelector(
  (dispatch: any) => dispatch,
  (_: any, isTimeline?: false) => isTimeline,
  (_: any, __: any, timelines: any) => timelines,
  (dispatch, isTimeline, timelines) => (index: any, ids: any) => {
    if (isTimeline) {
      const { events, ...timeline } = timelines;
      const eventsToMove = events.filter((event: any) => ids.includes(event.id));
      const remainingEvents = events.filter((event: any) => !ids.includes(event.id));
      const updatedEvents = [
        ...remainingEvents.slice(0, index),
        ...eventsToMove,
        ...remainingEvents.slice(index),
      ];
      dispatch(actions.updateTimelines({ timeline: { ...timeline, events: updatedEvents } }));
    } else dispatch(actions.updateAnnotationSort({ index, ids }));
  },
);

const updateDocumentSortSelector = createSelector(
  (
    dispatch: any,
    documents: any,
    currentFolder: any,
    setShowModal: any,
    isTimeline: any,
    timelines: any,
  ) => ({
    dispatch,
    documents,
    currentFolder,
    setShowModal,
    isTimeline,
    timelines,
  }),
  ({ dispatch, documents, currentFolder, setShowModal, isTimeline, timelines }) => (
    sortByColumn: any,
    sortDirection: any,
    onCustomSort: any,
  ) => {
    const fn = () => {
      const sortedData = documents
        .slice()
        .sort(getSortFunction(sortByColumn, sortDirection, onCustomSort));
      if (isTimeline) {
        dispatch(actions.updateTimelines({ timeline: { ...timelines, events: sortedData } }));
      } else {
        const ids = sortedData.map(({ id }: any) => id);
        dispatch(actions.updateAnnotationSort({ ids }));
      }
    };
    if (setShowModal) setShowModal({ fn });
    else fn();
  },
);

const filterByColumns = [
  'fileId',
  'fileName',
  {
    id: 'selectedText',
    accessor: (row: any) => row.selectedText || (row.annotation && row.annotation.selectedText),
  },
  'note',
  {
    id: 'comment',
    accessor: (row: any) =>
      row.comment || (row.annotation && row.annotation.data && row.annotation.data.comment),
  },
  {
    id: 'createdBy',
    accessor: ({ annotation }: any) =>
      annotation &&
      annotation.createdBy &&
      `${moment(annotation.createdBy.date)
        .utc()
        .format('DD/MM/YYYY HH:mm')} ${annotation.createdBy.name}`,
  },
  'shared',
];

export default React.memo(({ onRowSelected, onFilterCategoryChanged }: any) => {
  const dispatch = useDispatch();
  const caseId = useSelector(selectCurrentCaseId);
  const { quickLink } = useSelector(selectParams) as any;
  const { timeline } = useFetchTimeline();
  const annotations = useSelector(selectAnnotations) as any;
  const isTimeline = useSelector(selectTimeline) && quickLink === 'timelines';
  const data = isTimeline ? timeline?.events : annotations;
  const currentFolder = useSelector(selectCurrentFolder);
  const { annotationId } = useSelector(selectParams) as any;
  const currentUrl = useSelector(selectMatchedUrl);
  const docSearchVal = useSelector(selectDocSearchVal);
  const present = useSelector(
    ({ matcher: { query = {} } }: any) => query.present as 'private' | 'public' | undefined,
  );
  const { searchHandler, searchFunctionsHandler, clearSearch } = useSearchContext();
  const [showModal, setShowModal] = useState<any>(undefined);
  const defaultFilters = {
    term: '',
    shared: null,
  };

  const [filter, setFilter] = useState(defaultFilters);
  const [sortMode, setSortMode] = useState(false);
  const isAllAnnotations =
    useSelector(selectUserSettingAllAnnotations) &&
    currentUrl.includes(`${caseId}/all-annotations`);
  const updateDocumentSort = updateDocumentSortSelector(
    dispatch,
    data,
    currentFolder,
    typeof showModal === 'undefined' && setShowModal,
    isTimeline,
    timeline,
  );
  const setDocSearchVal = (val: any, location: any) => {
    if (isTimeline && docSearchVal !== val) dispatch(actionsV.setDocSearch(val, location));
    else if (!isTimeline && docSearchVal) dispatch(actionsV.setDocSearch('', location));
  };
  const columns = columnsSelector(
    sortMode,
    setSortMode,
    updateDocumentSort,
    clearSearch,
    filter.term,
    isAllAnnotations,
    isTimeline,
  );
  const updateSortHandler = updateSortHandlerSelector(dispatch, isTimeline, timeline);
  const onDoubleClickHandler = onDoubleClickHandlerSelector(
    present,
    isTimeline,
    setDocSearchVal,
    dispatch,
  );

  const filterByTerm = (term: any) => {
    setFilter((prev: any) => ({
      ...prev,
      term: term ? term : '',
    }));
    onRowSelected && onRowSelected([]);
  };

  const filterByOptions = (category: any) => {
    setFilter((prev: any) => ({ ...prev, ...category }));
    onRowSelected && onRowSelected([]);
    onFilterCategoryChanged && onFilterCategoryChanged(category);
  };

  const onMount = () => {
    searchFunctionsHandler &&
      searchFunctionsHandler({
        searchChanged: filterByTerm,
        filterChanged: filterByOptions,
      });
  };

  useEffect(() => onMount(), []);

  useEffect(() => {
    if (clearSearch !== null && clearSearch !== undefined && typeof clearSearch === 'function') {
      clearSearch();
    }
    searchHandler &&
      searchHandler({
        isReady: true,
        placeholder: T.translate('case.filter'),
        selectedColumns: [...columns, { id: 'tags' }].map(({ id }) => id),
        isAnnotation: true,
        documents: data,
      });
  }, [data, clearSearch, searchHandler]);

  useEffect(() => {
    const unlisten = history.listen(e => {
      const withoutFilesPart = (url: any) => url.replace(/\/files\/.*$/, '');
      const withoutTrailingSlashVal = withoutTrailingSlash(e.pathname);
      const withoutFilesPartVal = withoutFilesPart(withoutTrailingSlashVal);
      const regexArray = /[^/]*$/.exec(withoutFilesPartVal);
      const uriCurrentFolder = regexArray && regexArray[0];
      if (
        (currentFolder.code && uriCurrentFolder !== currentFolder.code) ||
        (Object.keys(currentFolder).length === 0 &&
          !e.pathname.includes(`${caseId}/all-annotations`) &&
          !e.pathname.includes(`${caseId}/quick-links/timelines`) &&
          (isAllAnnotations || isTimeline)) ||
        (e.pathname.includes(`${caseId}/quick-links/timelines`) && !isTimeline) ||
        (e.pathname.includes(`${caseId}/all-annotations`) && !isAllAnnotations)
      ) {
        clearSearch && clearSearch();
        dispatch(actions.clearAnnotations());
        dispatch(actionsV.setDocSearch(''));
        dispatch(actionsV.setGoToPageNumber(null));
        dispatch(actions.setCurrentSelectedFile(null));
      }
    });
    if (
      currentFolder.level === 0 &&
      (currentFolder.type === 'tags' || currentFolder.type === 'timelines')
    )
      return unlisten;
    else if ((currentFolder.id && currentFolder.type === 'tags') || isAllAnnotations) {
      dispatch(
        actions.fetchAnnotations({
          tagId: currentFolder.id,
          isAllAnnotations,
        }),
      );
    } else if (currentFolder.id && currentFolder.type === 'timelines') {
      dispatch(actions.fetchTimeline({ timelineId: currentFolder.id }));
    }
    return unlisten;
  }, [dispatch, currentFolder, isAllAnnotations, caseId, clearSearch, isTimeline]);

  useEffect(() => {
    if (annotationId) {
      data.forEach((doc: any) => {
        if (doc.id === annotationId && !doc.selected) doc.selected = true;
        else doc.selected = false;
      });
      onRowSelected && onRowSelected(data.filter(({ selected }: any) => !!selected));
    }
  }, [annotationId, data, onRowSelected]);

  return (
    <>
      {data && data.length > 0 && (
        <Table
          data={data}
          filter={filter}
          filterByColumns={filterByColumns}
          onDoubleClick={onDoubleClickHandler}
          columns={columns}
          sortMode={sortMode}
          onRowSelected={onRowSelected}
          updateSort={updateSortHandler}
          rowHeight={61}
        />
      )}
      <Modal
        show={!!showModal}
        handleSave={() => {
          setShowModal(false);
          showModal.fn();
        }}
        handleClose={() => setShowModal(undefined)}
        title={T.translate('generic.areYouSure')}
        saveTitle={T.translate('generic.yes')}
      >
        {T.translate('case.areYouSureSort')}
      </Modal>
    </>
  );
});
