export const camelCaseToRegular = str =>
  str
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function(str) {
      return str.toUpperCase();
    });

export const kebabCaseToRegular = str =>
  str
    // insert a space instead of dash
    .replace(/-/g, ' ')
    // uppercase the first character
    .replace(/^./, function(str) {
      return str.toUpperCase();
    });

export const regularToKebab = str =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
    .filter(Boolean)
    .map(x => x.toLowerCase())
    .join('-');
